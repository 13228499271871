import { useState, useContext } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useNavigation,
} from "@pankod/refine-core";

import {
	List,
	Table,
	useTable,
	Space,
	EditButton,
	DeleteButton,
	Tag,
	ImageField,
	ListButton,
	Button,
	Alert,
	ShowButton,
} from "@pankod/refine-antd";

import { IRequest } from "interfaces";
import { API_URL } from "../../constants";
import { ContactConfirmAlert } from "components/extra";
import { LoginContext } from "../../contexts/authContext";
import { IsSuperAdmin } from "../../helpers/utilities";

export const MyRequests: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();
	const { edit, show } = useNavigation();
	const { push } = useNavigation();
	const { userInfo } = useContext(LoginContext);

	let isPhoneConfirmed: boolean = true;
	if (userInfo) {
		const { type, phoneConfirmed } = JSON.parse(userInfo);
		if (type === translate("pages.login.fields.user")) {
			isPhoneConfirmed = phoneConfirmed;
		}
	}

	const { tableProps } = useTable<IRequest>({
		metaData: {
			populate: ["sport_major", "sport_style", "province", "photo"],
		},
	});

	return (
		<div>
			{userInfo && !isPhoneConfirmed ? (
				<ContactConfirmAlert />
			) : (
				<List
					title={translate("sider.myRequests")}
					canCreate={true}
					resource="requests"
				>
					<Table
						{...tableProps}
						pagination={false}
						scroll={{ x: 700 }}
						rowKey="id"
					>
						{/* <Table.Column<IRequest>
							title={translate("users.fields.avatar")}
							dataIndex="photo"
							render={(_, record) => (
								<ImageField
									value={record.photo}
									title={record.photo}
									width={30}
								/>
							)}
						/> */}
						<Table.Column
							dataIndex="createdAt"
							key="createdAt"
							width={150}
							title={translate("requests.fields.createdAt")}
							render={(value) => {
								return (
									new Date(value).toLocaleTimeString("fa-IR") +
									"  -  " +
									new Date(value).toLocaleDateString("fa-IR")
								);
							}}
						/>
						<Table.Column
							dataIndex="isDraft"
							key="isDraft"
							title={translate("common.status")}
							width={150}
							render={(value) => {
								return (
									<Tag color={value === true ? "red" : "green"}>
										{value === true
											? translate("common.draft")
											: translate("common.sent")}
									</Tag>
								);
							}}
						/>
						<Table.Column
							dataIndex={"photo"}
							align="center"
							title={translate("common.photo")}
							width={150}
							render={(value) => {
								return value ? (
									<ImageField
										value={API_URL + value[0].url}
										alt={value[0]?.name}
										title={value[0]?.name}
										width={48}
										preview={{ mask: <></> }}
									/>
								) : (
									<span>---</span>
								);
							}}
						/>
						<Table.Column
							dataIndex="name"
							key="name"
							title={translate("requests.fields.name")}
							width={150}
						/>
						<Table.Column
							dataIndex="lastName"
							key="lastName"
							width={150}
							title={translate("requests.fields.lastName")}
						/>
						<Table.Column
							dataIndex="fatherName"
							key="fatherName"
							title={translate("requests.fields.fatherName")}
							width={150}
						/>
						<Table.Column
							dataIndex="gender"
							key="gender"
							width={150}
							title={translate("requests.fields.gender")}
						/>
						<Table.Column
							dataIndex="DOB"
							key="DOB"
							width={150}
							title={translate("requests.fields.DOB")}
							render={(value) => {
								return new Date(value).toLocaleDateString("fa-IR");
							}}
						/>
						<Table.Column
							dataIndex="placeOfBirth"
							key="placeOfBirth"
							title={translate("requests.fields.placeOfBirth")}
							width={150}
						/>
						<Table.Column
							dataIndex="job"
							key="job"
							title={translate("requests.fields.job")}
							width={150}
						/>
						<Table.Column
							dataIndex="education"
							key="education"
							width={150}
							title={translate("requests.fields.education")}
						/>
						<Table.Column
							dataIndex="studyMajor"
							key="studyMajor"
							title={translate("requests.fields.studyMajor")}
							width={150}
						/>
						<Table.Column
							dataIndex="contactOne"
							key="contactOne"
							title={translate("requests.fields.contactOne")}
							width={150}
						/>
						<Table.Column
							dataIndex="contactTwo"
							key="contactTwo"
							title={translate("requests.fields.contactTwo")}
							width={150}
						/>
						<Table.Column
							dataIndex="identificationCard"
							key="identificationCard"
							title={translate("requests.fields.identificationCard")}
							width={150}
						/>
						<Table.Column
							dataIndex="identificationID"
							key="identificationID"
							title={translate("requests.fields.identificationID")}
							width={150}
						/>
						<Table.Column
							dataIndex="tazkiraID"
							key="tazkiraID"
							title={translate("requests.fields.tazkiraID")}
							width={150}
						/>
						<Table.Column
							key="[sport_major][id]"
							dataIndex={["sport_major", "title"]}
							title={translate("sport-majors.sport-major")}
							width={150}
						/>
						<Table.Column
							key="[sport_style][id]"
							dataIndex={["sport_style", "title"]}
							title={translate("sport-styles.sport-style")}
							width={150}
						/>
						<Table.Column
							key="[province][id]"
							dataIndex={["province", "title"]}
							title={translate("provinces.province")}
							width={150}
						/>
						<Table.Column
							dataIndex="currentAddress"
							key="currentAddress"
							title={translate("requests.fields.currentAddress")}
							width={300}
						/>
						<Table.Column
							dataIndex="mainAddress"
							key="mainAddress"
							title={translate("requests.fields.mainAddress")}
							width={300}
						/>
						<Table.Column
							dataIndex="honors"
							key="honors"
							title={translate("requests.fields.honors")}
							width={350}
						/>
						<Table.Column
							dataIndex="hasCoachingCertificate"
							key="hasCoachingCertificate"
							title={translate("requests.fields.CoachingCertificate")}
							width={150}
							render={(value) => {
								return (
									<Tag color={value === true ? "green" : "red"}>
										{value === true
											? translate("common.has")
											: translate("common.hasNot")}
									</Tag>
								);
							}}
						/>
						<Table.Column
							dataIndex="technicalLevel"
							key="technicalLevel"
							title={translate("requests.fields.technicalLevel")}
							width={150}
						/>
						<Table.Column
							dataIndex="hasArbitrationCertificate"
							key="hasArbitrationCertificate"
							title={translate("requests.fields.ArbitrationCertificate")}
							width={150}
							render={(value) => {
								return (
									<Tag color={value === true ? "green" : "red"}>
										{value === true
											? translate("common.has")
											: translate("common.hasNot")}
									</Tag>
								);
							}}
						/>
						<Table.Column
							dataIndex="federationAdminApproval"
							key="federationAdminApproval"
							title={translate("requests.fields.federationAdminApproval")}
							width={200}
							render={(value) => {
								return (
									<Tag color={value === true ? "green" : "red"}>
										{value === true
											? translate("common.approved")
											: translate("common.notApproved")}
									</Tag>
								);
							}}
						/>
						<Table.Column
							dataIndex="provincialAdminApproval"
							key="provincialAdminApproval"
							title={translate("requests.fields.provincialAdminApproval")}
							width={200}
							render={(value) => {
								return (
									<Tag color={value === true ? "green" : "red"}>
										{value === true
											? translate("common.approved")
											: translate("common.notApproved")}
									</Tag>
								);
							}}
						/>
						<Table.Column
							dataIndex="sportmajorAdminApproval"
							key="sportmajorAdminApproval"
							title={translate("requests.fields.sportmajorAdminApproval")}
							width={200}
							render={(value) => {
								return (
									<Tag color={value === true ? "green" : "red"}>
										{value === true
											? translate("common.approved")
											: translate("common.notApproved")}
									</Tag>
								);
							}}
						/>
						<Table.Column
							dataIndex="currentClubMembership"
							key="currentClubMembership"
							title={translate("requests.fields.currentClubMembership")}
							width={200}
						/>
						<Table.Column
							dataIndex="formerMemberships"
							key="formerMemberships"
							title={translate("requests.fields.formerMemberships")}
							width={230}
						/>

						<Table.Column<{
							id: string;
							federationAdminApproval: boolean;
							provincialAdminApproval: boolean;
							sportmajorAdminApproval: boolean;
							isDraft: boolean;
						}>
							width={140}
							fixed={"right"}
							title={translate("table.actions")}
							dataIndex="actions"
							render={(_, record) => (
								<Space>
									<ShowButton
										hideText
										onClick={() => show("requests", record.id)}
										size="small"
										recordItemId={record.id}
									/>
									{record.isDraft && (
										<>
											<EditButton
												hideText
												onClick={() =>
													record.federationAdminApproval === true &&
													record.sportmajorAdminApproval === true &&
													record.provincialAdminApproval === true
														? null
														: edit("requests", record.id)
												}
												size="small"
												recordItemId={record.id}
												disabled={
													record.federationAdminApproval === true &&
													record.sportmajorAdminApproval === true &&
													record.provincialAdminApproval === true &&
													true
												}
											/>
											<DeleteButton
												disabled={
													record.federationAdminApproval === true &&
													record.sportmajorAdminApproval === true &&
													record.provincialAdminApproval === true &&
													true
												}
												hideText
												size="small"
												recordItemId={record.id}
											/>
										</>
									)}
									<ListButton
										hideText
										size="small"
										onClick={() => push(`/notifications/${record.id}`)}
									/>
								</Space>
							)}
						/>
					</Table>
				</List>
			)}
		</div>
	);
};

import React, { useContext, useState, useRef } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
	useShow,
	useUpdate,
	useNotification,
	useCreate,
} from "@pankod/refine-core";

import {
	Icons,
	Divider,
	ErrorComponent,
	Show,
	Tag,
	Descriptions,
	ImageField,
	Upload,
	Button,
	Row,
	Col,
	Modal,
	Form,
	Input,
} from "@pankod/refine-antd";

import { API_URL } from "../../constants";
import { IsSuperAdmin, sendsms } from "helpers/utilities";
import { LoginContext } from "../../contexts/authContext";
import { useParams } from "@pankod/refine-react-router-v6";
import { axiosInstance } from "authProvider";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { IdentificationForm } from "components/extra";

export const RequestShow: React.FC<IResourceComponentsProps> = () => {
	const barcodeRef = useRef<any>(null);
	const cardFrontRef = useRef<any>(null);
	const cardBackRef = useRef<any>(null);
	const formRef = useRef<any>(null);
	const [isModalOpen, setIsModalOpen] = useState({ open: false, field: "" });
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const [isModalOpen3, setIsModalOpen3] = useState(false);
	const [isModalOpen4, setIsModalOpen4] = useState(false);
	const { TextArea } = Input;
	const [rejectionComment, setRejectionComment] = useState("");
	const { mutate } = useUpdate();
	const { mutate: mutateCreate } = useCreate();
	const { open } = useNotification();
	const { id } = useParams();
	const { userInfo } = useContext(LoginContext);
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();
	const { queryResult } = useShow({
		metaData: {
			populate: [
				"sport_major",
				"sport_style",
				"province",
				"photo",
				"files",
				"users_permissions_user",
				"paymentReceipt",
			],
		},
	});
	const { data, isLoading } = queryResult;
	const record = data?.data;
	const files = data?.data?.files;

	const uploadedFiles = files?.map(
		({ id, name, url }: { id: string; name: string; url: string }) => ({
			uid: id,
			name,
			url: API_URL + url,
		})
	);

	async function updateRecord(action: boolean, field: string) {
		let values: any;

		if (userInfo) {
			let { id: user_id, type, requests } = JSON.parse(userInfo);

			if (field !== "") {
				if (field === "provincialAdminApproval") {
					type = translate("pages.login.fields.provinceAdmin");
				} else if (field === "sportmajorAdminApproval") {
					type = translate("pages.login.fields.sportMajorAdmin");
				} else if (field === "federationAdminApproval") {
					type = translate("pages.login.fields.federationAdmin");
				}
			}

			const { data: requestedUser } = await axiosInstance.get(
				`${API_URL}/api/users?filters[requests][id][$eq]=${record?.id}&fields[0]=id&fields[1]=contact`
			);

			if (type === translate("pages.login.fields.provinceAdmin")) {
				if (record?.sportmajorAdminApproval === false) {
					return open?.({
						type: "error",
						message: translate("common.sportMajorNotApproved"),
					});
				}
				values = { provincialAdminApproval: action };
			} else if (type === translate("pages.login.fields.sportMajorAdmin")) {
				values = { sportmajorAdminApproval: action };
			} else if (type === translate("pages.login.fields.federationAdmin")) {
				if (record?.provincialAdminApproval === false) {
					return open?.({
						type: "error",
						message: translate("common.provinceAdminNotApproved"),
					});
				}
				values = { federationAdminApproval: action };
			}

			mutate({
				resource: "requests",
				values,
				id: Number(record?.id),
			});

			if (type === translate("pages.login.fields.sportMajorAdmin")) {
				const { data: provinceAdmin } = await axiosInstance.get(
					`${API_URL}/api/users?fields[0]=id&filters[$or][0][type][$eq]=${translate(
						"pages.login.fields.provinceAdmin"
					)}&filters[$and][1][province][id][$eq]=${record?.province?.id}`
				);
				provinceAdmin.push({ id: requestedUser[0]?.id });

				mutateCreate({
					resource: "notifications",
					values: {
						message:
							action === true
								? translate("reqNotifs.approvedBySportMajorAdmin")
								: translate("reqNotifs.rejectedBySportMajorAdmin"),
						request: Number(record?.id),
						users: provinceAdmin,
						comment: rejectionComment,
						accepted: action === true ? true : false,
						user_id: user_id,
					},
				});

				// send sms
				if (requestedUser[0]?.contact !== "") {
					sendsms(
						requestedUser[0]?.contact,
						action === true
							? translate("reqNotifs.approvedBySportMajorAdmin")
							: translate("reqNotifs.rejectedBySportMajorAdmin")
					);
				}
			} else if (type === translate("pages.login.fields.provinceAdmin")) {
				const { data: federationAdmin } = await axiosInstance.get(
					`${API_URL}/api/users?fields[0]=id&filters[$and][0][type][$eq]=${translate(
						"pages.login.fields.federationAdmin"
					)}`
				);
				federationAdmin.push({ id: requestedUser[0]?.id });

				mutateCreate({
					resource: "notifications",
					values: {
						message:
							action === true
								? translate("reqNotifs.approvedByProvinceAdmin")
								: translate("reqNotifs.rejectedByProvinceAdmin"),
						request: Number(record?.id),
						users: federationAdmin,
						comment: rejectionComment,
						accepted: action === true ? true : false,
						user_id: user_id,
					},
				});

				// send sms
				if (requestedUser[0]?.contact !== "") {
					sendsms(
						requestedUser[0]?.contact,
						action === true
							? translate("reqNotifs.approvedByProvinceAdmin")
							: translate("reqNotifs.rejectedByProvinceAdmin")
					);
				}
			} else if (type === translate("pages.login.fields.federationAdmin")) {
				mutateCreate({
					resource: "notifications",
					values: {
						message:
							action === true
								? translate("reqNotifs.approvedByFederationAdmin")
								: translate("reqNotifs.rejectedByFederationAdmin"),
						request: Number(record?.id),
						users: requestedUser[0]?.id,
						comment: rejectionComment,
						accepted: action === true ? true : false,
						user_id: user_id,
					},
				});

				// send sms
				if (requestedUser[0]?.contact !== "") {
					sendsms(
						requestedUser[0]?.contact,
						action === true
							? translate("reqNotifs.approvedByFederationAdmin")
							: translate("reqNotifs.rejectedByFederationAdmin")
					);
				}
			}
		}
	}

	const handleReject = () => {
		updateRecord(false, isModalOpen.field);
		setIsModalOpen({ open: false, field: "" });
	};

	const handleCancel = () => {
		setIsModalOpen({ open: false, field: "" });
	};

	const handlePrint = useReactToPrint({
		content: () => barcodeRef.current,
	});

	const handleCardFrontPrint = useReactToPrint({
		content: () => cardFrontRef.current,
	});

	const handleCardBackPrint = useReactToPrint({
		content: () => cardBackRef.current,
	});

	const handlePrintForm = useReactToPrint({
		content: () => formRef.current,
	});

	let userType = "";
	if (userInfo) {
		const { type, requests, province } = JSON.parse(userInfo);
		userType = type;
		if (type === translate("pages.login.fields.user")) {
			const myReq = requests.find((e: any) => e.id === Number(id));
			if (!myReq) {
				return <ErrorComponent />;
			}
		}
	}

	return (
		<Show
			headerButtons={<p></p>}
			canEdit={false}
			isLoading={isLoading}
			title={translate("common.show")}
			breadcrumb={false}
			goBack={
				currentLocale === "en" ? (
					<Icons.ArrowLeftOutlined />
				) : (
					<Icons.ArrowRightOutlined />
				)
			}
		>
			<Descriptions bordered>
				{record?.photo?.length && (
					<Descriptions.Item label={translate("common.photo")}>
						<ImageField
							value={API_URL + record?.photo[0]?.url || ""}
							alt={record?.photo[0]?.url}
							title={record?.photo[0]?.url}
							width={48}
							preview={{ mask: <></> }}
						/>
					</Descriptions.Item>
				)}
				<Descriptions.Item label={translate("requests.fields.name")}>
					{record?.name}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.lastName")}>
					{record?.lastName}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.fatherName")}>
					{record?.fatherName}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.DOB")}>
					{new Date(record?.DOB).toLocaleDateString("fa-IR")}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.placeOfBirth")}>
					{record?.placeOfBirth}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.job")}>
					{record?.job}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.education")}>
					{record?.education}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.studyMajor")}>
					{record?.studyMajor}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.gender")}>
					{record?.gender}
				</Descriptions.Item>

				<Descriptions.Item label={translate("requests.fields.contactOne")}>
					{record?.contactOne}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.contactTwo")}>
					{record?.contactTwo}
				</Descriptions.Item>
				<Descriptions.Item
					label={translate("requests.fields.identificationCard")}
				>
					{record?.identificationCard}
				</Descriptions.Item>
				<Descriptions.Item
					label={translate("requests.fields.identificationID")}
				>
					{record?.identificationID}
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.tazkiraID")}>
					{record?.tazkiraID}
				</Descriptions.Item>
				<Descriptions.Item
					label={translate("requests.fields.CoachingCertificate")}
				>
					<Tag
						color={record?.hasCoachingCertificate === true ? "green" : "red"}
					>
						{record?.hasCoachingCertificate === true
							? translate("common.has")
							: translate("common.hasNot")}
					</Tag>
				</Descriptions.Item>
				<Descriptions.Item label={translate("requests.fields.technicalLevel")}>
					{record?.technicalLevel}
				</Descriptions.Item>
				<Descriptions.Item
					label={translate("requests.fields.ArbitrationCertificate")}
				>
					<Tag
						color={record?.hasArbitrationCertificate === true ? "green" : "red"}
					>
						{record?.hasArbitrationCertificate === true
							? translate("common.has")
							: translate("common.hasNot")}
					</Tag>
				</Descriptions.Item>
				<Descriptions.Item label={translate("sport-majors.sport-major")}>
					{record?.sport_major?.title}
				</Descriptions.Item>
				<Descriptions.Item label={translate("sport-styles.sport-style")}>
					{record?.sport_style?.title}
				</Descriptions.Item>
				<Descriptions.Item label={translate("provinces.province")}>
					{record?.province?.title}
				</Descriptions.Item>
			</Descriptions>

			<Descriptions bordered>
				<Descriptions.Item
					label={translate("requests.fields.federationAdminApproval")}
				>
					<>
						<Tag
							color={record?.federationAdminApproval === true ? "green" : "red"}
						>
							{record?.federationAdminApproval === true
								? translate("common.has")
								: translate("common.hasNot")}
						</Tag>
						{userInfo &&
							JSON.parse(userInfo).type ===
								translate("pages.login.fields.admin") &&
							(record?.federationAdminApproval === true ? (
								<Button
									onClick={() =>
										setIsModalOpen({
											open: true,
											field: "federationAdminApproval",
										})
									}
									size={"small"}
									type="dashed"
									danger
									icon={<Icons.ExclamationOutlined />}
								>
									{translate("common.reject")}
								</Button>
							) : (
								<Button
									onClick={() => updateRecord(true, "federationAdminApproval")}
									size={"small"}
									type="primary"
									icon={<Icons.CheckOutlined />}
								>
									{translate("common.approve")}
								</Button>
							))}
					</>
				</Descriptions.Item>
				<Descriptions.Item
					label={translate("requests.fields.provincialAdminApproval")}
				>
					<>
						<Tag
							color={record?.provincialAdminApproval === true ? "green" : "red"}
						>
							{record?.provincialAdminApproval === true
								? translate("common.has")
								: translate("common.hasNot")}
						</Tag>
						{userInfo &&
							JSON.parse(userInfo).type ===
								translate("pages.login.fields.admin") &&
							(record?.provincialAdminApproval === true ? (
								<Button
									onClick={() =>
										setIsModalOpen({
											open: true,
											field: "provincialAdminApproval",
										})
									}
									size={"small"}
									type="dashed"
									danger
									icon={<Icons.ExclamationOutlined />}
								>
									{translate("common.reject")}
								</Button>
							) : (
								<Button
									onClick={() => updateRecord(true, "provincialAdminApproval")}
									size={"small"}
									type="primary"
									icon={<Icons.CheckOutlined />}
								>
									{translate("common.approve")}
								</Button>
							))}
					</>
				</Descriptions.Item>
				<Descriptions.Item
					label={translate("requests.fields.sportmajorAdminApproval")}
				>
					<>
						<Tag
							color={record?.sportmajorAdminApproval === true ? "green" : "red"}
						>
							{record?.sportmajorAdminApproval === true
								? translate("common.has")
								: translate("common.hasNot")}
						</Tag>
						{userInfo &&
							JSON.parse(userInfo).type ===
								translate("pages.login.fields.admin") &&
							(record?.sportmajorAdminApproval === true ? (
								<Button
									onClick={() =>
										setIsModalOpen({
											open: true,
											field: "sportmajorAdminApproval",
										})
									}
									size={"small"}
									type="dashed"
									danger
									icon={<Icons.ExclamationOutlined />}
								>
									{translate("common.reject")}
								</Button>
							) : (
								<Button
									onClick={() => updateRecord(true, "sportmajorAdminApproval")}
									size={"small"}
									type="primary"
									icon={<Icons.CheckOutlined />}
								>
									{translate("common.approve")}
								</Button>
							))}
					</>
				</Descriptions.Item>
			</Descriptions>

			<Descriptions bordered layout="vertical">
				<Descriptions.Item
					label={translate("requests.fields.currentClubMembershipLabel")}
				>
					{record?.currentClubMembership}
				</Descriptions.Item>
			</Descriptions>
			<Descriptions bordered layout="vertical">
				<Descriptions.Item
					label={translate("requests.fields.nationalTeamMembershipLabel")}
				>
					{record?.nationalTeamMembership}
				</Descriptions.Item>
			</Descriptions>
			<Descriptions bordered layout="vertical">
				<Descriptions.Item
					label={translate("requests.fields.formerMembershipsLabel")}
				>
					{record?.formerMemberships}
				</Descriptions.Item>
			</Descriptions>
			<Descriptions bordered layout="vertical">
				<Descriptions.Item label={translate("requests.fields.currentAddress")}>
					{record?.currentAddress}
				</Descriptions.Item>
			</Descriptions>
			<Descriptions bordered layout="vertical">
				<Descriptions.Item label={translate("requests.fields.mainAddress")}>
					{record?.mainAddress}
				</Descriptions.Item>
			</Descriptions>
			<Descriptions bordered layout="vertical">
				<Descriptions.Item label={translate("requests.fields.honors")}>
					{record?.honors}
				</Descriptions.Item>
			</Descriptions>

			{uploadedFiles && (
				<>
					<Divider />
					<Descriptions title={translate("common.uploadedFiles")}>
						<Descriptions.Item>
							<Upload
								showUploadList={{ removeIcon: true }}
								defaultFileList={uploadedFiles}
							/>
						</Descriptions.Item>
					</Descriptions>
				</>
			)}

			<Divider />
			<Descriptions title={translate("common.paymentReceipt")}>
				{record?.paymentReceipt?.length && (
					<>
						<Descriptions.Item>
							<ImageField
								value={API_URL + record?.paymentReceipt[0]?.url || ""}
								alt={record?.paymentReceipt[0]?.url}
								title={record?.paymentReceipt[0]?.url}
								width={48}
								preview={{ mask: <></> }}
							/>
						</Descriptions.Item>
						<Descriptions.Item label={translate("common.paymentReceiptNo")}>
							{record?.paymentReceiptNo}
						</Descriptions.Item>
					</>
				)}
			</Descriptions>
			<Divider />

			{!(
				(userInfo && JSON.parse(userInfo).type == "ورزشکار") ||
				(userInfo && JSON.parse(userInfo).type == "ریاست نمایندگی")
			) && (
				<>
					<Divider />
					<Button
						onClick={() => updateRecord(true, "")}
						type="primary"
						icon={<Icons.CheckOutlined />}
						style={{ marginLeft: 10, marginBottom: 10 }}
					>
						{translate("common.approveRequest")}
					</Button>
					<Button
						onClick={() => setIsModalOpen({ open: true, field: "" })}
						type="dashed"
						danger
						icon={<Icons.ExclamationOutlined />}
					>
						{translate("common.rejectRequest")}
					</Button>
				</>
			)}

			<div style={{ marginTop: 10 }}>
				{userInfo && JSON.parse(userInfo).type == "ریاست نمایندگی" && (
					<>
						<Button
							onClick={() => setIsModalOpen2(true)}
							type="primary"
							icon={<Icons.CheckOutlined />}
							style={{ marginLeft: 10, marginBottom: 10 }}
						>
							{translate("common.barcode")}
						</Button>

						<Button
							onClick={() => setIsModalOpen3(true)}
							type="primary"
							icon={<Icons.PrinterOutlined />}
							style={{ marginLeft: 10, marginBottom: 10 }}
						>
							{translate("common.printIdentificationCard")}
						</Button>
					</>
				)}
				<Button
					onClick={handlePrintForm}
					type="primary"
					icon={<Icons.UnorderedListOutlined />}
					style={{ marginLeft: 10, marginBottom: 10 }}
				>
					{translate("common.printForm")}
				</Button>
			</div>

			{/* request rejection comment modal */}
			<Modal
				title={translate("common.rejectRequest")}
				open={isModalOpen.open}
				onOk={handleReject}
				onCancel={handleCancel}
			>
				<Form layout="vertical">
					<Form.Item label={translate("reqNotifs.comments")}>
						<TextArea
							value={rejectionComment}
							onChange={(e) => setRejectionComment(e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>

			{/* print barcode modal */}
			<Modal
				title={translate("common.barcode")}
				open={isModalOpen2}
				onCancel={() => setIsModalOpen2(false)}
				footer={[
					<Button
						onClick={handlePrint}
						type="primary"
						icon={<Icons.PrinterOutlined />}
						style={{ marginLeft: 10, marginBottom: 10 }}
					>
						{translate("common.print")}
					</Button>,
				]}
			>
				<div
					ref={barcodeRef}
					style={{
						height: "auto",
						margin: "0 auto",
						maxWidth: "80%",
						width: "100%",
						padding: 20,
					}}
				>
					<QRCode
						size={500}
						style={{ height: "auto", maxWidth: "100%", width: "100%" }}
						value={window.location.origin + `/user-info/${id}`}
						viewBox={`0 0 500 500`}
					/>
				</div>
			</Modal>

			{/* print business card modal */}
			<Modal
				title={translate("common.printIdentificationCard")}
				open={isModalOpen3}
				onCancel={() => setIsModalOpen3(false)}
				width={750}
				footer={[]}
			>
				<div
					style={{
						width: "100%",
						overflow: "auto",
					}}
				>
					{/* front design */}
					<p style={{ marginBottom: 10 }}>
						{translate("requests.fields.frontSide")}
					</p>

					<div style={{ backgroundColor: "lightgray" }}>
						<div
							ref={cardFrontRef}
							style={{
								width: "700px",
								height: "447px",
								// backgroundImage: "url(https://i.ibb.co/KxqL227/card-front.jpg)",
								backgroundSize: "cover",
								position: "relative",
							}}
						>
							{/* first row */}
							<p
								style={{
									position: "absolute",
									right: 30,
									top: 210,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>سمت:</span>
							</p>

							<p
								style={{
									position: "absolute",
									right: 300,
									top: 210,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.lastName")}:
								</span>
								{record?.lastName}
							</p>

							<div
								style={{
									position: "absolute",
									left: 30,
									top: 180,
									width: 130,
									height: 150,
								}}
							>
								{record?.photo?.length && (
									<img
										src={API_URL + record?.photo[0]?.url}
										width="100%"
										height="100%"
										style={{ objectFit: "cover" }}
									/>
								)}
							</div>

							{/* second row */}
							<p
								style={{
									position: "absolute",
									right: 30,
									top: 260,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.name")}:
								</span>
								{record?.name}
							</p>

							<p
								style={{
									position: "absolute",
									right: 300,
									top: 260,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.DOB")}:
								</span>
								{record?.DOB}
							</p>

							{/* third row */}
							<p
								style={{
									position: "absolute",
									right: 30,
									top: 310,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.fatherName")}:
								</span>
								{record?.fatherName}
							</p>

							<p
								style={{
									position: "absolute",
									right: 300,
									top: 310,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.stayingPlace")}:
								</span>
								{record?.province?.title}
							</p>

							{/* fourth row */}
							<p
								style={{
									position: "absolute",
									right: 30,
									top: 360,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.identificationCard")}:
								</span>
								{record?.identificationCard}
							</p>

							<p
								style={{
									position: "absolute",
									right: 300,
									top: 360,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.sport_major")}:
								</span>
								{record?.sport_major?.title}
							</p>

							{/* fifth row */}
							<p
								style={{
									position: "absolute",
									right: 30,
									top: 405,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.identificationID")}:
								</span>
								<span>{record?.identificationID}</span>
							</p>

							<p
								style={{
									position: "absolute",
									right: 300,
									top: 405,
									direction: "rtl",
								}}
							>
								<span style={{ fontWeight: "bold", marginLeft: 10 }}>
									{translate("requests.fields.sportCardID")}:
								</span>
								{record?.id}
							</p>
						</div>
					</div>

					<Button
						onClick={handleCardFrontPrint}
						type="primary"
						icon={<Icons.PrinterOutlined />}
						style={{ marginBottom: 10, marginTop: 10 }}
					>
						{translate("common.print")}
					</Button>

					<Divider />

					{/* back design */}
					<p style={{ marginBottom: 10 }}>
						{translate("requests.fields.backSide")}
					</p>

					<div style={{ backgroundColor: "lightgray" }}>
						<div
							ref={cardBackRef}
							style={{
								width: "700px",
								height: "447px",
								// backgroundImage: "url(https://i.ibb.co/KxqL227/card-front.jpg)",
								backgroundSize: "cover",
								position: "relative",
							}}
						>
							<div
								style={{
									position: "absolute",
									bottom: 70,
									left: 60,
									width: 120,
									height: 120,
									backgroundColor: "white",
									padding: 10,
									borderRadius: 10,
								}}
							>
								<QRCode
									size={120}
									style={{ height: "auto", maxWidth: "100%", width: "100%" }}
									value={window.location.origin + `/user-info/${id}`}
									viewBox={`0 0 500 500`}
								/>
							</div>
						</div>
					</div>

					<Button
						onClick={handleCardBackPrint}
						type="primary"
						icon={<Icons.PrinterOutlined />}
						style={{ marginBottom: 10, marginTop: 10 }}
					>
						{translate("common.print")}
					</Button>
				</div>
			</Modal>

			{/* print form */}
			<div style={{ display: "none" }}>
				<div
					ref={formRef}
					style={{
						height: "auto",
						margin: "0 auto",
						width: "100%",
						padding: 20,
					}}
				>
					<IdentificationForm record={record} />
				</div>
			</div>
		</Show>
	);
};

import React, { useState } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
} from "@pankod/refine-core";

import {
	Create,
	Form,
	Input,
	Select,
	useForm,
	useSelect,
	Icons,
	ErrorComponent,
} from "@pankod/refine-antd";

import { mediaUploadMapper } from "@pankod/refine-strapi-v4";

import { ISportStyle } from "interfaces";
import { IsSuperAdmin } from "helpers/utilities";

export const SportStyleCreate: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();
	const { formProps, saveButtonProps } = useForm<ISportStyle>();

	const { selectProps } = useSelect({
		resource: "sport-majors",
	});

	if (IsSuperAdmin() === false) {
		return <ErrorComponent />;
	}

	return (
		<Create
			saveButtonProps={saveButtonProps}
			goBack={
				currentLocale === "en" ? (
					<Icons.ArrowLeftOutlined />
				) : (
					<Icons.ArrowRightOutlined />
				)
			}
		>
			<Form
				{...formProps}
				layout="vertical"
				onFinish={(values) => {
					return (
						formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
					);
				}}
			>
				<Form.Item
					wrapperCol={{ span: 14 }}
					label={translate("sport-styles.sport-style")}
					name="title"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					wrapperCol={{ span: 14 }}
					label={translate("sport-majors.sport-major")}
					name="sport_major"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Select {...selectProps} />
				</Form.Item>
			</Form>
		</Create>
	);
};

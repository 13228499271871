import { useState, useContext } from "react";
import {
	Alert,
	Button,
	Divider,
	Icons,
	message,
	Modal,
	Space,
} from "@pankod/refine-antd";
import { useApiUrl, useTranslate } from "@pankod/refine-core";
import axios from "axios";
import { OtpInput } from "../index";
import { LoginContext } from "../../../contexts/authContext";
import { axiosInstance } from "../../../authProvider";

export const ContactConfirmAlert: React.FC<any> = (props) => {
	const translate = useTranslate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [otp, setOtp] = useState("");
	const [codeSent, setCodeSent] = useState(false);
	const [verificationCode, setVerificationCode] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const { userInfo } = useContext(LoginContext);
	const API_URL = useApiUrl();

	const sendSMS = async () => {
		if (userInfo) {
			const { contact } = JSON.parse(userInfo);
			setLoading(true);
			const code = Math.random() * 90000;

			await axiosInstance
				.post(`${API_URL}/sendotp`, {
					data: { contact, code },
				})
				.then((response) => {
					setLoading(false);
					setCodeSent(true);
					setVerificationCode(Math.floor(code) + 10000);
					return message.success(translate("common.enterVerificationCodeMsg"));
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					return message.error(
						translate("common.errorOccurred"),
						error.response
					);
				});
		}
	};

	const onChange = (value: string) => setOtp(value);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async () => {
		try {
			if (userInfo) {
				if (Number(verificationCode) === Number(otp)) {
					setConfirmLoading(true);
					const { id: userId } = JSON.parse(userInfo);
					const { data } = await axiosInstance.put(
						`${API_URL}/users/${userId}`,
						{
							phoneConfirmed: true,
						}
					);

					if (data) {
						message.success(translate("common.phoneConfirmed"));

						setTimeout(function () {
							window.location.reload();
						}, 2000);
					}
				} else {
					setConfirmLoading(false);
					return message.error(translate("common.wrongOtpCode"));
				}
			}
		} catch (err: any) {
			setConfirmLoading(false);
			console.log(err.message);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Alert
				style={{ marginBottom: 20 }}
				message={translate("common.confirmContactMsg")}
				type="warning"
				action={
					<Space>
						<Button onClick={showModal} size="small" type="dashed">
							{translate("common.confirmContact")}
						</Button>
					</Space>
				}
			/>

			<Modal
				title={translate("common.confirmContact")}
				open={isModalOpen}
				onOk={handleOk}
				confirmLoading={confirmLoading}
				onCancel={handleCancel}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						flexWrap: "wrap",
						alignItems: "center",
					}}
				>
					<Button
						onClick={sendSMS}
						loading={loading}
						size="middle"
						type="primary"
					>
						{translate("common.sendConfirmationSms")}
					</Button>
					<Divider />
					{codeSent && (
						<Alert
							style={{ marginBottom: 20 }}
							message={translate("common.enterVerificationCodeMsg")}
							type="warning"
						/>
					)}
					<OtpInput value={otp} valueLength={5} onChange={onChange} />
				</div>
			</Modal>
		</>
	);
};

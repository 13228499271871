import React, { useState } from "react";
import {
	UpdatePasswordPageProps,
	UpdatePasswordFormTypes,
} from "@pankod/refine-core";
import {
	Row,
	Col,
	Layout,
	Card,
	Typography,
	Form,
	Input,
	Button,
	LayoutProps,
	CardProps,
	FormProps,
	message,
} from "antd";
import { useTranslate, useUpdatePassword } from "@pankod/refine-core";

import { layoutStyles, containerStyles, titleStyles } from "../styles";
import axios from "axios";
import { API_URL } from "../../../constants";
import {
	Link,
	useNavigate,
	useSearchParams,
} from "@pankod/refine-react-router-v6";

const { Text, Title } = Typography;

type UpdatePasswordProps = UpdatePasswordPageProps<
	LayoutProps,
	CardProps,
	FormProps
>;

/**
 * **refine** has update password page form which is served on `/update-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#update-password} for more details.
 */
export const UpdatePasswordPage: React.FC<UpdatePasswordProps> = ({
	wrapperProps,
	contentProps,
	renderContent,
	formProps,
}) => {
	const [form] = Form.useForm<UpdatePasswordFormTypes>();
	const [loading, setLoading] = useState(false);
	const push = useNavigate();
	const translate = useTranslate();
	const { mutate: updatePassword, isLoading } =
		useUpdatePassword<UpdatePasswordFormTypes>();
	const [searchParams] = useSearchParams();
	const code = searchParams.get("code");

	const handleSubmit = (values: any) => {
		setLoading(true);

		values.code = code;

		return axios
			.post(`${API_URL}/api/auth/reset-password`, values)
			.then((response) => {
				setLoading(false);
				message.success(translate("common.resetPassSuccessMsg"));
				push("/login");
			})
			.catch((error) => {
				setLoading(false);
				return message.error(translate("common.errorOccurred"), error.response);
			});
	};

	const CardTitle = (
		<Title level={3} style={titleStyles}>
			{translate("pages.updatePassword.title", "Set New Password")}
		</Title>
	);

	const CardContent = (
		<Card
			title={CardTitle}
			headStyle={{ borderBottom: 0 }}
			style={containerStyles}
			{...(contentProps ?? {})}
		>
			<Form<UpdatePasswordFormTypes>
				layout="vertical"
				form={form}
				onFinish={(values) => handleSubmit(values)}
				requiredMark={false}
				{...formProps}
			>
				<Form.Item
					name="password"
					label={translate(
						"pages.updatePassword.fields.password",
						"New Password"
					)}
					rules={[{ required: true }]}
					style={{ marginBottom: "12px" }}
				>
					<Input type="password" placeholder="●●●●●●●●" size="large" />
				</Form.Item>
				<Form.Item
					name="passwordConfirmation"
					label={translate(
						"pages.updatePassword.fields.confirmPassword",
						"Confirm New Password"
					)}
					hasFeedback
					dependencies={["password"]}
					rules={[
						{
							required: true,
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue("password") === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(
										translate(
											"pages.updatePassword.errors.confirmPasswordNotMatch",
											"Passwords do not match"
										)
									)
								);
							},
						}),
					]}
					style={{ marginBottom: "12px" }}
				>
					<Input type="password" placeholder="●●●●●●●●" size="large" />
				</Form.Item>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "12px",
					}}
				>
					<Text
						style={{
							fontSize: 12,
							marginLeft: "auto",
						}}
					>
						{translate(
							"pages.register.buttons.haveAccount",
							"Have an account? "
						)}{" "}
						<Link
							style={{
								fontWeight: "bold",
							}}
							to="/login"
						>
							{translate("pages.login.signin", "Sign in")}
						</Link>
					</Text>
				</div>
				<Form.Item>
					<Button
						type="primary"
						size="large"
						htmlType="submit"
						loading={loading}
						block
					>
						{translate("pages.updatePassword.buttons.submit", "Update")}
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);

	return (
		<Layout style={layoutStyles} {...(wrapperProps ?? {})}>
			<Row
				justify="center"
				align="middle"
				style={{
					height: "100vh",
				}}
			>
				<Col xs={22}>
					{renderContent ? renderContent(CardContent) : CardContent}
				</Col>
			</Row>
		</Layout>
	);
};

import React, {
	PropsWithChildren,
	createContext,
	useEffect,
	useState,
} from "react";
import { ConfigProvider, theme } from "@pankod/refine-antd";

type ColorModeContextType = {
	mode: string;
	setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
	{} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const colorModeFromLocalStorage = localStorage.getItem("colorMode");
	const isSystemPreferenceDark = window?.matchMedia(
		"(prefers-color-scheme: dark)"
	).matches;

	const systemPreference = isSystemPreferenceDark ? "dark" : "light";
	const [mode, setMode] = useState(
		colorModeFromLocalStorage || systemPreference
	);

	useEffect(() => {
		// window.localStorage.setItem("colorMode", mode);
		window.localStorage.setItem("colorMode", "light");
	}, [mode]);

	const setColorMode = () => {
		if (mode === "light") {
			setMode("dark");
		} else {
			setMode("light");
		}
	};

	const { darkAlgorithm, defaultAlgorithm } = theme;

	return (
		<ColorModeContext.Provider
			value={{
				setMode: setColorMode,
				mode,
			}}
		>
			<ConfigProvider
				theme={{
					algorithm: mode === "light" ? defaultAlgorithm : darkAlgorithm,
				}}
			>
				{children}
			</ConfigProvider>
		</ColorModeContext.Provider>
	);
};

import { useTranslate } from "@pankod/refine-core";
import "./styles.css";
import { API_URL } from "../../../constants";

export const IdentificationForm: React.FC<any> = ({ record }) => {
	const translate = useTranslate();

	return (
		<div className="print-wrapper" dir="rtl">
			<div className="header-wrapper" id="right">
				<div className="item">
					<img src="/images/1.jpg" />
				</div>
				<div className="item" id="middle">
					<img src="/images/3.jpg" />
					<h1>ریاست عمومی تربیت بدنی، ورزش و کمیته ملی المپیک افغانستان</h1>
					<h1>
						نمایندگی ریاست عمومی تربیت بدنی، ورزش و کمیته ملی المپیک افغانستان
						در ایران
					</h1>
					<h1 style={{ textDecoration: "underline" }}>
						فرم اخذ کارت هویت ورزشی مربیان، داوران و ورزشکاران
					</h1>
				</div>
				<div className="item" id="left">
					<img src="/images/2.jpg" />
				</div>
			</div>
			<div className="body-wrapper">
				<div className="number-date-wrapper">
					<p>شماره ( &nbsp; {record?.id} &nbsp; )</p>
					<p>
						تاریخ &nbsp;{" "}
						{new Date(record?.createdAt).toLocaleDateString("fa-IR")}
					</p>
				</div>
				<table>
					<tbody>
						<tr>
							<td>نام: {record?.name}</td>
							<td>نام خانوادگی: {record?.lastName}</td>
							<td>نام پدر: {record?.fatherName}</td>

							<td
								rowSpan={3}
								style={{
									textAlign: "center",
									backgroundImage: `${
										record?.photo?.length
											? `url(${API_URL + record?.photo[0]?.url})`
											: ""
									}`,
									backgroundPosition: "center",
									backgroundSize: "cover",
								}}
							>
								{!record?.photo?.length && <>محل عکس</>}
							</td>
						</tr>
						<tr>
							<td>
								تاریخ تولد: {new Date(record?.DOB).toLocaleDateString("fa-IR")}
							</td>
							<td>محل تولد: {record?.placeOfBirth}</td>
							<td>شغل: {record?.job}</td>
						</tr>
						<tr>
							<td>میزان تحصیلات: {record?.education}</td>
							<td>رشته تحصیلی: {record?.studyMajor}</td>
							<td>
								<p>شماره تماس: {record?.contactOne}</p>
								<p>شماره تماس: {record?.contactTwo}</p>
							</td>
						</tr>
						<tr>
							<td>نوع مدرک شناسایی: {record?.identificationCard}</td>
							<td>شماره مدرک: {record?.identificationID}</td>
							<td>شماره تذکره: {record?.tazkiraID}</td>
							<td>رشته ورزشی: {record?.sport_major?.title}</td>
						</tr>
						<tr>
							<td>سبک: {record?.sport_style?.title}</td>
							<td>
								مدرک مربیگری:{" "}
								{record?.hasCoachingCertificate ? "دارد" : "ندارد"}
							</td>
							<td>درجه فنی: {record?.technicalLevel}</td>
							<td>
								مدرک داوری:{" "}
								{record?.hasArbitrationCertificate ? "دارد" : "ندارد"}
							</td>
						</tr>
						<tr>
							<td colSpan={4}>
								عضویت باشگاه فعلی خود را اعلام بفرمایید!{" "}
								{record?.currentClubMembership}! آیا تا اکنون عضویت تیم های ملی
								افغانستان را داشته اید؟ توضیح دهید:{" "}
								{record?.nationalTeamMembership}
							</td>
						</tr>
						<tr>
							<td colSpan={3}>
								عضویت و سمت های ورزشی که تا کنون داشته اید را توضیح دهید:{" "}
								{record?.formerMemberships}
							</td>
							<td style={{ textAlign: "center" }}>
								<p>اثر انگشت و محل امضا</p>
								<p>متقاضی</p>
							</td>
						</tr>
						<tr>
							<td colSpan={3}>آدرس سکونت اصلی: {record?.mainAddress}</td>
						</tr>
					</tbody>
				</table>
				<p className="note">
					لطفا کپی مدارک ذکر شده در فرم (پاسپورت، کارت آمایش، تذکره افغانستان،
					مدرک مربیگری، داوری و درجه فنی، مدرک تحصیلی) به همراه دو قطعه عکس
					3X4پرسنلی را پیوست همین فرم ارسال بدارید
				</p>
			</div>
			<div className="footer-wrapper">
				<div className="item">
					<p>نماینده رشته ورزشی</p>
					<p>محل امضاء</p>
				</div>
				<div className="item">
					<p>مسئول نمایندگی استان</p>
					<p>محل امضاء</p>
				</div>
				<div className="item">
					<p>ریاست نمایندگی</p>
					<p>محل امضاء</p>
				</div>
			</div>
		</div>
	);
};

import { useContext } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
	useGetIdentity,
} from "@pankod/refine-core";

import {
	Edit,
	Form,
	Input,
	useForm,
	Icons,
	Select,
	Switch,
	useSelect,
	ErrorComponent,
	Upload,
	message,
} from "@pankod/refine-antd";

import { IUser } from "interfaces";
import { useParams } from "@pankod/refine-react-router-v6";
import {
	getValueProps,
	useStrapiUpload,
	mediaUploadMapper,
} from "@pankod/refine-strapi-v4";
import { TOKEN_KEY, API_URL } from "../../constants";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
	const { data: user } = useGetIdentity();
	const urlPath: string = window.location.href;
	const { id: userId } = useParams();

	const { formProps, saveButtonProps, queryResult, redirect, formLoading } =
		useForm<IUser>({
			metaData: { populate: ["province", "photo"] },
			redirect: urlPath.includes("profile") ? false : "list",
		});
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();
	const { ...uploadProps } = useStrapiUpload;

	const { selectProps } = useSelect({
		resource: "provinces",
	});

	if (Number(userId) !== Number(user?.id) && urlPath.includes("profile")) {
		return <ErrorComponent />;
	}

	return (
		<Edit
			isLoading={formLoading}
			recordItemId={userId}
			title={
				urlPath.includes("profile")
					? translate("sider.profile")
					: translate("common.edit")
			}
			saveButtonProps={saveButtonProps}
			goBack={
				urlPath.includes("profile") ? (
					false
				) : currentLocale === "en" ? (
					<Icons.ArrowLeftOutlined />
				) : (
					<Icons.ArrowRightOutlined />
				)
			}
			breadcrumb
		>
			<Form
				{...formProps}
				layout="vertical"
				onFinish={(values) => {
					return (
						formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
					);
				}}
			>
				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("users.fields.username")}
					name="username"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("users.fields.email")}
					name="email"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
						{
							type: "email",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("users.fields.contact")}
					name="contact"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="role" initialValue={1} hidden />

				{!urlPath.includes("profile") && (
					<Form.Item
						wrapperCol={{ span: 10 }}
						label={translate("users.fields.type")}
						name="type"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select
							placeholder={translate("pages.login.fields.type")}
							options={[
								{
									value: translate("pages.login.fields.admin"),
									label: translate("pages.login.fields.admin"),
								},
								{
									value: translate("pages.login.fields.federationAdmin"),
									label: translate("pages.login.fields.federationAdmin"),
								},
								{
									value: translate("pages.login.fields.provinceAdmin"),
									label: translate("pages.login.fields.provinceAdmin"),
								},
								{
									value: translate("pages.login.fields.sportMajorAdmin"),
									label: translate("pages.login.fields.sportMajorAdmin"),
								},
								{
									value: translate("pages.login.fields.user"),
									label: translate("pages.login.fields.user"),
								},
							]}
						/>
					</Form.Item>
				)}

				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("provinces.province")}
					name={["province", "id"]}
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Select {...selectProps} />
				</Form.Item>

				<Form.Item label={translate("common.photo")}>
					<Form.Item
						name="photo"
						valuePropName="fileList"
						getValueProps={(data) => getValueProps(data, API_URL)}
						noStyle
					>
						<Upload.Dragger
							beforeUpload={(file: any, FileList: any) => {
								if (Number(file.size / 1024 / 1024) > 1) {
									message.error(translate("common.fileSizeMsg"));
									return Upload.LIST_IGNORE;
								} else {
									return true;
								}
							}}
							accept=".png,.jpeg"
							name="files"
							action={`${API_URL}/api/upload`}
							headers={{
								Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
							}}
							listType="picture"
							multiple={false}
							{...uploadProps}
						>
							<p className="ant-upload-text">{translate("common.photoMsg")}</p>
						</Upload.Dragger>
					</Form.Item>
				</Form.Item>

				{!urlPath.includes("profile") && (
					<Form.Item
						wrapperCol={{ span: 10 }}
						label={translate("common.block")}
						name="blocked"
					>
						{queryResult?.data?.data?.blocked === true ? (
							<Switch defaultChecked />
						) : (
							<Switch />
						)}
					</Form.Item>
				)}
			</Form>
		</Edit>
	);
};

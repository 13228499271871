import axios from "axios";
import React, { useState, createContext, useEffect } from "react";
import { API_URL, TOKEN_KEY } from "../constants";

interface LoginProviderProps {
	children: React.ReactNode;
}

export const LoginContext = createContext({
	userInfo: "",
	setUserInfo: (userInfo: string) => {},
});

export const LoginProvider = ({ children }: LoginProviderProps) => {
	const [userInfo, setUserInfo] = useState("");

	useEffect(() => {
		const fetchUserInfo = async () => {
			const token = localStorage.getItem(TOKEN_KEY);
			if (!token) {
				return Promise.reject();
			}

			const res = await axios.get(`${API_URL}/api/users/me?populate=*`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			setUserInfo(JSON.stringify(res.data));
		};

		fetchUserInfo();
	}, []);

	return (
		<LoginContext.Provider value={{ userInfo, setUserInfo }}>
			{children}
		</LoginContext.Provider>
	);
};

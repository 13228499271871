import { useTranslate } from "@pankod/refine-core";
import axios from "axios";
import { LoginContext } from "contexts/authContext";
import { useContext } from "react";
import { axiosInstance } from "../authProvider";
import { API_URL } from "../constants";

export const TranslateUserTypes = (userType: string) => {
	const translate = useTranslate();

	if (userType.length < 1) {
		return;
	}

	if (userType.length > 0) {
		if (userType === "admin") {
			return translate("pages.login.fields.admin");
		} else if (userType === "federationAdmin") {
			return translate("pages.login.fields.federationAdmin");
		} else if (userType === "admprovinceAdminin") {
			return translate("pages.login.fields.provinceAdmin");
		} else if (userType === "sportMajorAdmin") {
			return translate("pages.login.fields.sportMajorAdmin");
		} else if (userType === "user") {
			return translate("pages.login.fields.user");
		} else {
			return "";
		}
	}
};

export const IsSuperAdmin = () => {
	const translate = useTranslate();
	const { userInfo } = useContext(LoginContext);
	if (userInfo) {
		const { type } = JSON.parse(userInfo);

		if (type === translate("pages.login.fields.admin")) {
			return true;
		} else {
			return false;
		}
	}
};

export function isNumber(str: string) {
	return /^\d+$/.test(str);
}

export async function sendsms(contact: string, message: string) {
	await axiosInstance
		.post(`${API_URL}/api/sendsms`, {
			data: { contact, message },
		})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}

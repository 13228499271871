import { useState } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
	useApiUrl,
} from "@pankod/refine-core";

import {
	Create,
	Form,
	Input,
	useForm,
	Select,
	Icons,
	Switch,
	useSelect,
	Upload,
	message,
} from "@pankod/refine-antd";

import { IUser } from "interfaces";
import {
	getValueProps,
	mediaUploadMapper,
	useStrapiUpload,
} from "@pankod/refine-strapi-v4";
import { TOKEN_KEY, API_URL } from "../../constants";
import { axiosInstance } from "../../authProvider";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
	const API_URL = useApiUrl();
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();
	const { formProps, saveButtonProps } = useForm<IUser>();
	const { ...uploadProps } = useStrapiUpload;
	const [sendSMS, setSendSMS] = useState<boolean>(false);

	const { selectProps } = useSelect({
		resource: "provinces",
	});

	const onChangeSMS = (checked: boolean) => {
		setSendSMS(checked);
	};

	const onFinish = async (values: any) => {
		const emailExists = await axiosInstance.get(
			`${API_URL}/users?filters[email][$eq]=${values?.email}&fields[0]=id`
		);

		if (emailExists?.data?.length) {
			message.error(translate("pages.error.uniqueEmail"));
			return;
		}

		const phoneExists = await axiosInstance.get(
			`${API_URL}/users?filters[contact][$eq]=${values?.contact}&fields[0]=id`
		);

		if (phoneExists?.data?.length) {
			message.error(translate("pages.error.uniquePhoneNumber"));
			return;
		}

		if (sendSMS === true) {
			await axiosInstance
				.post(`${API_URL}/sendcredentials`, {
					data: {
						contact: values?.contact,
						url: window.location.origin,
						email: values?.email,
						password: values?.password,
						phoneNumber: values?.contact,
					},
				})
				.catch((error) => {
					console.log(error);
					return message.error(
						translate("common.errorOccurred"),
						error.response
					);
				});
		}

		return formProps.onFinish && formProps.onFinish(mediaUploadMapper(values));
	};

	return (
		<Create
			saveButtonProps={saveButtonProps}
			goBack={
				currentLocale === "en" ? (
					<Icons.ArrowLeftOutlined />
				) : (
					<Icons.ArrowRightOutlined />
				)
			}
		>
			<Form {...formProps} layout="vertical" onFinish={onFinish}>
				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("users.fields.username")}
					name="username"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("users.fields.email")}
					name="email"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
						{
							type: "email",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					wrapperCol={{ span: 10 }}
					name="password"
					label={translate("pages.login.fields.password", "Password")}
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
					style={{ marginBottom: "12px" }}
				>
					<Input type="password" placeholder="●●●●●●●●" size="large" />
				</Form.Item>

				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("users.fields.contact")}
					name="contact"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="role" initialValue={1} hidden />
				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("users.fields.type")}
					name="type"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Select
						placeholder={translate("pages.login.fields.type")}
						options={[
							{
								value: translate("pages.login.fields.admin"),
								label: translate("pages.login.fields.admin"),
							},
							{
								value: translate("pages.login.fields.federationAdmin"),
								label: translate("pages.login.fields.federationAdmin"),
							},
							{
								value: translate("pages.login.fields.provinceAdmin"),
								label: translate("pages.login.fields.provinceAdmin"),
							},
							{
								value: translate("pages.login.fields.sportMajorAdmin"),
								label: translate("pages.login.fields.sportMajorAdmin"),
							},
							{
								value: translate("pages.login.fields.user"),
								label: translate("pages.login.fields.user"),
							},
						]}
					/>
				</Form.Item>
				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("provinces.province")}
					name="province"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Select {...selectProps} />
				</Form.Item>

				<Form.Item label={translate("common.photo")}>
					<Form.Item
						name="photo"
						valuePropName="fileList"
						getValueProps={(data) => getValueProps(data, API_URL)}
						noStyle
					>
						<Upload.Dragger
							beforeUpload={(file: any, FileList: any) => {
								if (Number(file.size / 1024 / 1024) > 1) {
									message.error(translate("common.fileSizeMsg"));
									return Upload.LIST_IGNORE;
								} else {
									return true;
								}
							}}
							accept=".png,.jpeg"
							name="files"
							action={`${API_URL}/upload`}
							headers={{
								Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
							}}
							listType="picture"
							multiple={false}
							maxCount={1}
							{...uploadProps}
						>
							<p className="ant-upload-text">{translate("common.photoMsg")}</p>
						</Upload.Dragger>
					</Form.Item>
				</Form.Item>

				<Form.Item
					wrapperCol={{ span: 10 }}
					label={translate("common.sendSMS")}
				>
					<Switch onChange={onChangeSMS} />
				</Form.Item>
			</Form>
		</Create>
	);
};

import React from "react";
import { CardProps, FormProps, LayoutProps } from "antd";
import { AuthPageProps } from "@pankod/refine-core";

import { LoginPage } from "pages/Auth/login";
import { RegisterPage } from "pages/Auth/register";
import { ForgotPasswordPage } from "pages/Auth/forget-password";
import { UpdatePasswordPage } from "pages/Auth/update-password";

export type AuthProps = AuthPageProps<LayoutProps, CardProps, FormProps>;

/**
 * **refine** has a default auth page form served on the `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/authpage/} for more details.
 */
export const AuthPage: React.FC<AuthProps> = (props) => {
	const { type } = props;
	const renderView = () => {
		switch (type) {
			case "register":
				return <RegisterPage {...props} />;
			case "forgotPassword":
				return <ForgotPasswordPage {...props} />;
			case "updatePassword":
				return <UpdatePasswordPage {...props} />;
			default:
				return <LoginPage {...props} />;
		}
	};

	return <>{renderView()}</>;
};

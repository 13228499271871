import { useState } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	CrudFilters,
	HttpError,
	useMany,
} from "@pankod/refine-core";

import {
	List,
	Table,
	useTable,
	getDefaultSortOrder,
	FilterDropdown,
	Select,
	useSelect,
	Space,
	EditButton,
	DeleteButton,
	Row,
	Col,
	Card,
	Form,
	Input,
	Icons,
	Button,
	ErrorComponent,
} from "@pankod/refine-antd";

import { ISportStyle, ISportFilterVariables, ISportMajor } from "interfaces";
import { IsSuperAdmin } from "helpers/utilities";

export const SportStyleList: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();

	const { tableProps, sorter, searchFormProps } = useTable<
		ISportStyle,
		HttpError,
		ISportFilterVariables
	>({
		initialSorter: [
			{
				field: "id",
				order: "desc",
			},
		],
		metaData: {
			populate: ["sport_major"],
		},
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { title, sport_major } = params;

			filters.push(
				{
					field: "title",
					operator: "contains",
					value: title,
				},
				{
					field: "sport_major.id",
					operator: "eq",
					value: sport_major,
				}
			);

			return filters;
		},
	});

	const sportMajorIds =
		tableProps?.dataSource?.map((item) => item.sport_major.id) ?? [];
	const { data, isLoading } = useMany<ISportMajor>({
		resource: "sport-majors",
		ids: sportMajorIds,
		queryOptions: {
			enabled: sportMajorIds.length > 0,
		},
	});

	const { selectProps } = useSelect({
		resource: "sport-majors",
		optionLabel: "title",
		optionValue: "id",
	});

	if (IsSuperAdmin() === false) {
		return <ErrorComponent />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col lg={6} xs={24}>
				<Card title={translate("common.filters")}>
					<Filter formProps={searchFormProps} />
				</Card>
			</Col>
			<Col lg={18} xs={24}>
				<List>
					<Table
						{...tableProps}
						rowKey="id"
						pagination={{
							...tableProps.pagination,
						}}
					>
						<Table.Column
							dataIndex="title"
							key="title"
							title={translate("sport-styles.sport-styles")}
							defaultSortOrder={getDefaultSortOrder("title", sorter)}
							sorter={{ multiple: 2 }}
							showSorterTooltip={false}
							filtered
						/>
						<Table.Column
							key="[sport_major][id]"
							dataIndex={["sport_major", "title"]}
							title={translate("sport-majors.sport-majors")}
						/>
						<Table.Column<{ id: string }>
							title={translate("table.actions")}
							dataIndex="actions"
							render={(_, record) => (
								<Space>
									<EditButton hideText size="small" recordItemId={record.id} />
									<DeleteButton
										hideText
										size="small"
										recordItemId={record.id}
									/>
								</Space>
							)}
						/>
					</Table>
				</List>
			</Col>
		</Row>
	);
};

const Filter: React.FC<{ formProps: any }> = ({ formProps }) => {
	const translate = useTranslate();
	const { selectProps: sportMajorSelectProps } = useSelect<ISportMajor>({
		resource: "sport-majors",
	});

	return (
		<Form layout="vertical" {...formProps}>
			<Form.Item label={translate("common.search")} name="title">
				<Input
					placeholder={translate("common.title")}
					prefix={<Icons.SearchOutlined />}
				/>
			</Form.Item>
			<Form.Item
				label={translate("sport-majors.sport-major")}
				name="sport_major"
			>
				<Select
					{...sportMajorSelectProps}
					allowClear
					placeholder={translate("sport-majors.sport-major")}
				/>
			</Form.Item>
			<Form.Item>
				<Button htmlType="submit" type="primary">
					{translate("common.filter")}
				</Button>
			</Form.Item>
		</Form>
	);
};

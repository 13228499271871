import { useState, useContext, useEffect } from "react";
import {
	AntdLayout,
	ConfigProvider,
	Menu,
	Grid,
	Icons,
	Drawer,
	Sider as DefaultSider,
	Button,
} from "@pankod/refine-antd";
import {
	useTranslate,
	useLogout,
	useTitle,
	CanAccess,
	ITreeMenu,
	useIsExistAuthentication,
	useRouterContext,
	useMenu,
	useRefineContext,
} from "@pankod/refine-core";

import { Title as DefaultTitle } from "../title";

import { drawerButtonStyles } from "./styles";
import { IsSuperAdmin } from "helpers/utilities";
import { LoginContext } from "../../../contexts/authContext";

const {
	UnorderedListOutlined,
	LogoutOutlined,
	DashboardOutlined,
	BarsOutlined,
} = Icons;
const { SubMenu } = Menu;

export const Sider: typeof DefaultSider = ({ render }) => {
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const isExistAuthentication = useIsExistAuthentication();
	const { Link } = useRouterContext();
	const { mutate: mutateLogout } = useLogout();
	const Title = useTitle();
	const translate = useTranslate();
	const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
	const breakpoint = Grid.useBreakpoint();
	const { hasDashboard } = useRefineContext();
	const { userInfo } = useContext(LoginContext);

	let newArray: any = [];

	if (userInfo) {
		const { type } = JSON.parse(userInfo);
		newArray = [...menuItems];
		if (type === translate("pages.login.fields.user")) {
			newArray.splice(1, 5);
		} else if (
			type === translate("pages.login.fields.provinceAdmin") ||
			type === translate("pages.login.fields.federationAdmin")
		) {
			newArray.splice(0, 1);
			newArray.splice(1, 3);
			newArray[1].label = translate("pages.login.fields.sportMajorAdmins");
		} else if (type === translate("pages.login.fields.sportMajorAdmin")) {
			newArray.splice(0, 1);
			newArray.splice(1, 4);
		} else {
			// menuItems.splice(0, 1);
			// menuItems.splice(1, 4);
			newArray.splice(0, 1);
		}
	}

	// if (IsSuperAdmin() === false) {
	// 	if (userInfo) {
	// 		const { type } = JSON.parse(userInfo);
	// 		if (type === translate("pages.login.fields.user")) {
	// 		} else if (
	// 			type === translate("pages.login.fields.provinceAdmin") ||
	// 			type === translate("pages.login.fields.federationAdmin")
	// 		) {
	// 			newArray.splice(0, 1);
	// 			newArray.splice(1, 3);
	// 			newArray[1].label = translate("pages.login.fields.sportMajorAdmins");
	// 		} else {
	// 			newArray.splice(0, 1);
	// 			newArray.splice(1, 4);
	// 		}
	// 	}
	// } else {
	// 	newArray.splice(0, 1);
	// }

	const isMobile =
		typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

	const RenderToTitle = Title ?? DefaultTitle;

	const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
		return tree.map((item: ITreeMenu) => {
			const { icon, label, route, name, children, parentName } = item;

			if (children.length > 0) {
				return (
					<CanAccess
						key={route}
						resource={name.toLowerCase()}
						action="list"
						params={{
							resource: item,
						}}
					>
						<SubMenu
							key={route}
							icon={icon ?? <UnorderedListOutlined />}
							title={label}
						>
							{renderTreeView(children, selectedKey)}
						</SubMenu>
					</CanAccess>
				);
			}
			const isSelected = route === selectedKey;
			const isRoute = !(parentName !== undefined && children.length === 0);
			return (
				<CanAccess
					key={route}
					resource={name.toLowerCase()}
					action="list"
					params={{
						resource: item,
					}}
				>
					<Menu.Item
						key={route}
						style={{
							fontWeight: isSelected ? "bold" : "normal",
						}}
						icon={icon ?? (isRoute && <UnorderedListOutlined />)}
					>
						<Link to={route}>{label}</Link>
						{!collapsed && isSelected && (
							<div className="ant-menu-tree-arrow" />
						)}
					</Menu.Item>
				</CanAccess>
			);
		});
	};

	const logout = isExistAuthentication && (
		<Menu.Item
			key="logout"
			onClick={() => mutateLogout()}
			icon={<LogoutOutlined />}
		>
			{translate("buttons.logout", "Logout")}
		</Menu.Item>
	);

	const dashboard = hasDashboard ? (
		<Menu.Item
			key="dashboard"
			style={{
				fontWeight: selectedKey === "/" ? "bold" : "normal",
			}}
			icon={<DashboardOutlined />}
		>
			<Link to="/">{translate("dashboard.title", "Dashboard")}</Link>
			{!collapsed && selectedKey === "/" && (
				<div className="ant-menu-tree-arrow" />
			)}
		</Menu.Item>
	) : null;

	const items = renderTreeView(newArray, selectedKey);

	const renderSider = () => {
		if (render) {
			return render({
				dashboard,
				items,
				logout,
				collapsed,
			});
		}
		return (
			<>
				{userInfo &&
					JSON.parse(userInfo).type !== translate("pages.login.fields.user") &&
					dashboard}
				{items}
				{logout}
			</>
		);
	};

	const renderMenu = () => {
		return (
			<Menu
				selectedKeys={[selectedKey]}
				defaultOpenKeys={defaultOpenKeys}
				mode="inline"
				onClick={() => {
					setDrawerOpen(false);
					if (!breakpoint.lg) {
						setCollapsed(true);
					}
				}}
			>
				{renderSider()}
			</Menu>
		);
	};

	const renderDrawerSider = () => {
		return (
			<>
				<Drawer
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
					placement="left"
					closable={false}
					width={200}
					bodyStyle={{
						padding: 0,
					}}
					maskClosable={true}
				>
					<AntdLayout>
						<AntdLayout.Sider style={{ height: "100vh", overflow: "hidden" }}>
							<RenderToTitle collapsed={false} />
							{renderMenu()}
						</AntdLayout.Sider>
					</AntdLayout>
				</Drawer>
				<Button
					style={drawerButtonStyles}
					size="large"
					onClick={() => setDrawerOpen(true)}
					icon={<BarsOutlined />}
				></Button>
			</>
		);
	};

	const renderContent = () => {
		if (isMobile) {
			return renderDrawerSider();
		}

		return (
			<AntdLayout.Sider
				// collapsible
				collapsed={collapsed}
				onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
				collapsedWidth={80}
				breakpoint="lg"
			>
				<RenderToTitle collapsed={collapsed} />
				{renderMenu()}
			</AntdLayout.Sider>
		);
	};

	return (
		<ConfigProvider
			theme={{
				components: {
					Menu: {
						colorItemBg: "transparent",
						colorItemText: "#fff",
						colorItemTextSelected: "#fff",
						colorItemBgSelected: "transparent",
						colorItemTextHover: "#fff",
					},
				},
			}}
		>
			{renderContent()}
		</ConfigProvider>
	);
};

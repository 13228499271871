import { AuthProvider } from "@pankod/refine-core";
import { AuthHelper } from "./helpers/auth";

import { TOKEN_KEY, API_URL } from "./constants";

import axios from "axios";

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthProvider = {
	login: async ({ email, password }) => {
		const { data, status } = await strapiAuthHelper.login(email, password);
		if (status === 200) {
			localStorage.setItem(TOKEN_KEY, data.jwt);
			localStorage.setItem("user", JSON.stringify(data));

			// set header axios instance
			axiosInstance.defaults.headers.common = {
				Authorization: `Bearer ${data.jwt}`,
			};

			return Promise.resolve(window.location.replace("/"));
		}

		return Promise.reject();
	},
	logout: () => {
		localStorage.removeItem(TOKEN_KEY);
		localStorage.removeItem("user");

		return Promise.resolve();
	},
	checkError: () => Promise.resolve(),
	checkAuth: () => {
		const token = localStorage.getItem(TOKEN_KEY);
		if (token) {
			axiosInstance.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			return Promise.resolve();
		}

		return Promise.reject();
	},
	getPermissions: () => Promise.resolve(),
	getUserIdentity: async () => {
		const token = localStorage.getItem(TOKEN_KEY);
		if (!token) {
			return Promise.reject();
		}

		const { data, status } = await strapiAuthHelper.me(token);
		if (status === 200) {
			const { id, username, email } = data;
			return Promise.resolve({
				id,
				username,
				email,
			});
		}

		return Promise.reject();
	},
};

import { useContext, useState, useEffect } from "react";
import {
	useGetLocale,
	useSetLocale,
	useGetIdentity,
	useTranslate,
	useLogout,
	useNavigation,
	useApiUrl,
} from "@pankod/refine-core";
import {
	AntdLayout,
	Space,
	Menu,
	Button,
	Icons,
	Dropdown,
	Avatar,
	Typography,
	Switch,
	Badge,
	MenuProps,
} from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";
import { ColorModeContext } from "contexts";
import { LoginContext } from "contexts/authContext";
import { axiosInstance } from "authProvider";

const { DownOutlined } = Icons;
const { Text } = Typography;

export const Header: React.FC = () => {
	const { push } = useNavigation();
	const { i18n } = useTranslation();
	const locale = useGetLocale();
	const changeLanguage = useSetLocale();
	const { data: user } = useGetIdentity();
	const { mode, setMode } = useContext(ColorModeContext);
	const translate = useTranslate();
	const { mutate: mutateLogout } = useLogout();
	const currentLocale = locale();
	const { userInfo } = useContext(LoginContext);
	const API_URL = useApiUrl();
	const [notifs, setNotifs] = useState([]);

	useEffect(() => {
		const fetchNotifs = async () => {
			if (userInfo) {
				const { type } = JSON.parse(userInfo);
				const { data } = await axiosInstance.get(
					`${API_URL}/notifications?filters[$and][0][users][type][$contains]=${type}&filters[$and][1][isRead][$eq]=false`
				);

				if (data) {
					setNotifs(data?.data);
				}
			}
		};

		fetchNotifs();
	}, [userInfo]);

	const handleMenuClick = (e: any) => {
		if (e.key == 1) {
			push(`/users/edit/${user?.id}?profile`);
		} else if (e.key == 2) {
			mutateLogout();
		}
	};

	const items = [
		{
			label: translate("sider.profile"),
			key: "1",
		},
		{
			label: translate("buttons.logout"),
			key: "2",
		},
	];

	const menuProps = {
		items,
		onClick: handleMenuClick,
	};

	const menu = (
		<Menu selectedKeys={currentLocale ? [currentLocale] : []}>
			{[...(i18n.languages || [])].sort().map((lang: string) => (
				<Menu.Item
					key={lang}
					onClick={() => changeLanguage(lang)}
					icon={
						<span style={{ marginRight: 8 }}>
							<Avatar size={16} src={`/images/flags/${lang}.svg`} />
						</span>
					}
				>
					{lang === "en" ? "English" : "Persian"}
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<AntdLayout.Header
			style={{
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center",
				padding: "0px 24px",
				height: "64px",
			}}
		>
			{/* <Switch
				checkedChildren="🌛"
				unCheckedChildren="🔆"
				onChange={() => setMode(mode === "light" ? "dark" : "light")}
				defaultChecked={mode === "dark"}
			/> */}
			{/* <Dropdown overlay={menu}>
				<Button type="link">
					<Space>
						<Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
						{currentLocale === "en" ? "English" : "فارسی"}
						<Button>
							<Icons.BellOutlined />
						</Button>
					</Space>
				</Button>
			</Dropdown> */}
			<Space style={{ marginLeft: "8px" }}>
				{/* <Dropdown menu={{ items2 }} placement="bottomLeft">
					<Badge count={5} offset={[10, 0]}>
						<Button>
							<Icons.BellOutlined />
						</Button>
					</Badge>
				</Dropdown> */}

				{/* <Dropdown overlay={menu} placement="bottomLeft">
					<Button type="link">
						<Space>
							<Badge dot offset={[10, 0]}>
								<Button>
									<Icons.BellOutlined />
								</Button>
							</Badge>
						</Space>
					</Button>
				</Dropdown> */}

				{notifs && notifs.length ? (
					<Badge dot offset={[10, 0]}>
						<Button onClick={() => push("/notifications")}>
							<Icons.BellOutlined />
						</Button>
					</Badge>
				) : (
					<Button onClick={() => push("/notifications")}>
						<Icons.BellOutlined />
					</Button>
				)}

				{user?.username && (
					<Dropdown.Button menu={menuProps} icon={<Icons.UserOutlined />}>
						{user.username}
					</Dropdown.Button>
				)}

				{/* {user?.username && (
					<Text ellipsis strong style={{ color: "white" }}>
						{user.username}
					</Text>
				)}
				{user?.avatar ? (
					<Avatar src={user?.avatar} alt={user?.username} />
				) : (
					<Avatar src={`/images/flags/avatar.jpg`} alt={user?.username} />
				)} */}
			</Space>
		</AntdLayout.Header>
	);
};

import { useState } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	CrudFilters,
	HttpError,
	useSelect,
} from "@pankod/refine-core";

import {
	List,
	Table,
	useTable,
	Space,
	EditButton,
	Row,
	Col,
	Card,
	DeleteButton,
	Form,
	Input,
	Icons,
	Button,
	ErrorComponent,
} from "@pankod/refine-antd";

import { ISportMajor, ISportFilterVariables } from "interfaces";
import { IsSuperAdmin } from "helpers/utilities";

export const SportMajorList: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();

	const { tableProps, searchFormProps } = useTable<
		ISportMajor,
		HttpError,
		ISportFilterVariables
	>({
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { title } = params;

			filters.push({
				field: "title",
				operator: "contains",
				value: title,
			});

			return filters;
		},
	});

	if (IsSuperAdmin() === false) {
		return <ErrorComponent />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col lg={6} xs={24}>
				<Card title={translate("common.filters")}>
					<Filter formProps={searchFormProps} />
				</Card>
			</Col>
			<Col lg={18} xs={24}>
				<List>
					<Table {...tableProps} rowKey="id">
						{/* <Table.Column dataIndex="id" title={translate("posts.fields.id")} /> */}
						<Table.Column
							dataIndex="title"
							title={translate("sport-majors.sport-majors")}
						/>
						<Table.Column<ISportMajor>
							title={translate("table.actions")}
							dataIndex="actions"
							render={(_, record) => (
								<Space>
									<EditButton size="small" hideText recordItemId={record.id} />
									<DeleteButton
										size="small"
										hideText
										recordItemId={record.id}
									/>
								</Space>
							)}
						/>
					</Table>
				</List>
			</Col>
		</Row>
	);
};

const Filter: React.FC<{ formProps: any }> = ({ formProps }) => {
	const translate = useTranslate();
	return (
		<Form layout="vertical" {...formProps}>
			<Form.Item label={translate("common.search")} name="title">
				<Input
					placeholder={translate("common.title")}
					prefix={<Icons.SearchOutlined />}
				/>
			</Form.Item>
			<Form.Item>
				<Button htmlType="submit" type="primary">
					{translate("common.filter")}
				</Button>
			</Form.Item>
		</Form>
	);
};

import { useContext } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	CrudFilters,
	HttpError,
	useNavigation,
	useApiUrl,
} from "@pankod/refine-core";

import {
	List,
	Table,
	useTable,
	getDefaultSortOrder,
	Space,
	ShowButton,
	Badge,
	Icons,
} from "@pankod/refine-antd";

import { INotification, INotificationFilterVariables } from "interfaces";
import { LoginContext } from "contexts/authContext";
import { axiosInstance } from "authProvider";

export const NotificationList: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();
	const { push } = useNavigation();
	const { userInfo } = useContext(LoginContext);
	const API_URL = useApiUrl();

	let userType, userId;
	if (userInfo) {
		const { id, type } = JSON.parse(userInfo);
		userType = type;
		userId = id;
	}

	const filters: CrudFilters = [
		{
			field: "users.type",
			operator: "contains",
			value: userType || "",
		},
	];

	if (userType !== translate("pages.login.fields.admin")) {
		filters.push({
			field: "users.id",
			operator: "eq",
			value: userId,
		});
	}

	const { tableProps, sorter } = useTable<
		INotification,
		HttpError,
		INotificationFilterVariables
	>({
		initialSorter: [
			{
				field: "id",
				order: "desc",
			},
		],
		metaData: {
			populate: ["request", "users", "user_id"],
		},
		initialFilter: filters,
		onSearch: (params) => {
			const filters: CrudFilters = [];
			const { message, request, isRead, createdAt } = params;

			filters.push(
				{
					field: "message",
					operator: "contains",
					value: message,
				},
				{
					field: "request.id",
					operator: "eq",
					value: request,
				},
				{
					field: "isRead",
					operator: "eq",
					value: isRead,
				}
			);

			return filters;
		},
	});

	// if (IsSuperAdmin() === false) {
	// 	return <ErrorComponent />;
	// }

	async function handleClick(id: any, reqId: any) {
		const { data } = await axiosInstance.put(`${API_URL}/notifications/${id}`, {
			data: {
				isRead: true,
			},
		});

		if (data) {
			push(`/requests/show/${reqId}`);
		}
	}

	return (
		<List title={translate("reqNotifs.notifications")}>
			<Table
				{...tableProps}
				rowKey="id"
				pagination={{
					...tableProps.pagination,
				}}
			>
				<Table.Column<{ request: any; isRead: string }>
					dataIndex="isRead"
					key="isRead"
					width={10}
					render={(_, record) =>
						_.toString() === "false" && <Badge dot offset={[10, 0]}></Badge>
					}
				/>
				<Table.Column<{ request: any; accepted: boolean }>
					dataIndex="accepted"
					key="accepted"
					width={10}
					render={(_, record) =>
						_ === true ? (
							<Icons.CheckOutlined />
						) : _ === false ? (
							<Icons.ExclamationOutlined />
						) : (
							<></>
						)
					}
				/>
				<Table.Column
					dataIndex="createdAt"
					key="createdAt"
					width={200}
					title={translate("requests.fields.createdAt")}
					render={(value) => {
						return (
							new Date(value).toLocaleTimeString("fa-IR") +
							"  -  " +
							new Date(value).toLocaleDateString("fa-IR")
						);
					}}
				/>
				<Table.Column
					key="[user_id][username]"
					dataIndex={["user_id", "username"]}
					title={translate("users.user")}
					width={200}
				/>
				<Table.Column
					dataIndex="message"
					key="message"
					title={translate("common.title")}
					defaultSortOrder={getDefaultSortOrder("message", sorter)}
					width={400}
				/>
				<Table.Column
					dataIndex="comment"
					key="comment"
					title={translate("reqNotifs.comments")}
					width={600}
				/>
				<Table.Column<{ request: any; id: string }>
					title={translate("table.actions")}
					dataIndex="actions"
					render={(_, record) => (
						<Space>
							<ShowButton
								hideText
								size="small"
								onClick={() => handleClick(record?.id, record?.request?.id)}
							/>
							{/* <EditButton hideText size="small" recordItemId={record.id} />
							<DeleteButton hideText size="small" recordItemId={record.id} /> */}
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};

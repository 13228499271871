import React, { useContext, useState } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
	useNavigation,
	useApiUrl,
	useCreate,
	useGetIdentity,
	useCreateMany,
} from "@pankod/refine-core";

import {
	Create,
	Form,
	Input,
	Select,
	useForm,
	useSelect,
	Icons,
	Switch,
	Divider,
	Upload,
	ErrorComponent,
	Tag,
	Space,
	Button,
	UploadProps,
	message,
	Checkbox,
	Spin,
} from "@pankod/refine-antd";

import {
	useStrapiUpload,
	mediaUploadMapper,
	getValueProps,
} from "@pankod/refine-strapi-v4";

import { IRequest } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import dayjs from "dayjs";
import { IsSuperAdmin } from "helpers/utilities";
import { LoginContext } from "contexts/authContext";
import { axiosInstance } from "authProvider";
import { Navigate, useNavigate } from "@pankod/refine-react-router-v6";
import { ContactConfirmAlert } from "components/extra";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import moment from "jalali-moment";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

export const RequestCreate: React.FC<IResourceComponentsProps> = () => {
	const [draft, setDraft] = useState(false);
	const [formLoading, setFormLoading] = useState<boolean>(false);
	const [fList, setFList] = useState<any>([
		{
			uid: "-1",
			name: "xxx.png",
			status: "done",
			url: "http://www.baidu.com/xxx.png",
		},
	]);
	const { data: user } = useGetIdentity();
	const navigate = useNavigate();
	const { listUrl } = useNavigation();
	const { userInfo } = useContext(LoginContext);
	const API_URL = useApiUrl();
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();
	const { formProps, saveButtonProps, onFinish } = useForm({
		redirect: false,
	});

	const { TextArea } = Input;
	const { selectProps } = useSelect({
		resource: "sport-majors",
	});
	const { selectProps: sportStylesSelectProps } = useSelect({
		resource: "sport-styles",
	});
	const { selectProps: provincesSelectProps } = useSelect({
		resource: "provinces",
	});
	const { mutate } = useCreateMany();

	const { ...uploadProps } = useStrapiUpload;

	if (IsSuperAdmin() === false) {
		if (userInfo) {
			const { type, phoneConfirmed } = JSON.parse(userInfo);
			if (type === translate("pages.login.fields.user")) {
				if (!phoneConfirmed) {
					return <ContactConfirmAlert />;
				}
			}
			if (type !== translate("pages.login.fields.user")) {
				return <ErrorComponent />;
			}
		}
	}

	const onChangeDraft = (e: CheckboxChangeEvent) => {
		if (e.target.checked) {
			setDraft(true);
		} else {
			setDraft(false);
		}
	};

	const handleSubmit = async (values: any) => {
		try {
			setFormLoading(true);
			const { DOB } = values;
			values = {
				...values,
				DOB: moment.from(
					DOB.year + "/" + DOB.month + "/" + DOB.day,
					"fa",
					"YYYY/MM/DD"
				),
			};

			if (!draft) {
				values = {
					...values,
					isDraft: false,
				};
			}

			const data = await onFinish(mediaUploadMapper(values));
			const reqId = data?.data?.data?.id;

			let done: boolean = false;
			if (!draft) {
				const { data: sportMajors } = await axiosInstance.get(
					`${API_URL}/users?fields[0]=id&filters[$or][0][type][$eq]=${translate(
						"pages.login.fields.sportMajorAdmin"
					)}`
				);

				const { data: federationAdmin } = await axiosInstance.get(
					`${API_URL}/users?fields[0]=id&filters[$and][0][type][$eq]=${translate(
						"pages.login.fields.federationAdmin"
					)}`
				);

				const { data: provinceAdmin } = await axiosInstance.get(
					`${API_URL}/users?fields[0]=id&filters[$or][0][type][$eq]=${translate(
						"pages.login.fields.provinceAdmin"
					)}&filters[$and][1][province][id][$eq]=${values?.province}`
				);

				mutate(
					{
						resource: "notifications",
						values: [
							{
								message: translate("reqNotifs.new"),
								request: Number(reqId),
								users: federationAdmin,
								user_id: user?.id,
							},
							{
								message: translate("reqNotifs.new"),
								request: Number(reqId),
								users: provinceAdmin,
								user_id: user?.id,
							},
							{
								message: translate("reqNotifs.new"),
								request: Number(reqId),
								users: sportMajors,
								user_id: user?.id,
							},
						],
					},
					{
						onSuccess: (data, variables, context) => {
							redir();
						},
					}
				);
			} else {
				redir();
			}
		} catch (error) {
			setFormLoading(false);
			console.log(error);
		}
	};

	function redir() {
		if (userInfo) {
			const { type } = JSON.parse(userInfo);
			if (type === translate("pages.login.fields.user")) {
				window.location.replace("/myrequests");
			} else {
				window.location.replace("/requests");
			}
		}
	}

	return (
		<Create
			title={translate("common.create")}
			// saveButtonProps={{ children: translate("common.save&send") }}

			saveButtonProps={saveButtonProps}
			breadcrumb={false}
			goBack={false}
			footerButtons={({ defaultButtons }) => (
				<>
					<Checkbox onChange={onChangeDraft}>
						{translate("common.saveasdraft")}
					</Checkbox>
					{/* <Button type="primary" onClick={handleSaveDraft}>
						{translate("common.save")}
					</Button> */}
					{defaultButtons}
				</>
			)}

			// goBack={
			// 	currentLocale === "en" ? (
			// 		<Icons.ArrowLeftOutlined />
			// 	) : (
			// 		<Icons.ArrowRightOutlined />
			// 	)
			// }
		>
			<Spin spinning={formLoading} delay={500}>
				<Form
					{...formProps}
					layout="inline"
					onFinish={(values) => {
						handleSubmit(values);
					}}
				>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.name")}
						name="name"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.lastName")}
						name="lastName"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.fatherName")}
						name="fatherName"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10, zIndex: 999 }}
						label={translate("requests.fields.DOB")}
						name="DOB"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<DatePicker shouldHighlightWeekends locale="fa" />
						{/* <DatePicker defaultValue={dayjs("1401-01-01", "YYYY-MM-DD")} /> */}
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.placeOfBirth")}
						name="placeOfBirth"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.job")}
						name="job"
					>
						<Input />
					</Form.Item>
					<Form.Item
						label={translate("requests.fields.education")}
						name="education"
					>
						<Select
							placeholder={translate("requests.fields.education")}
							options={[
								{
									value: translate("requests.fields.uneducated"),
									label: translate("requests.fields.uneducated"),
								},
								{
									value: translate("requests.fields.elementarySchool"),
									label: translate("requests.fields.elementarySchool"),
								},
								{
									value: translate("requests.fields.highSchool"),
									label: translate("requests.fields.highSchool"),
								},
								{
									value: translate("requests.fields.associateDegree"),
									label: translate("requests.fields.associateDegree"),
								},
								{
									value: translate("requests.fields.bachelorDegree"),
									label: translate("requests.fields.bachelorDegree"),
								},
								{
									value: translate("requests.fields.masterDegree"),
									label: translate("requests.fields.masterDegree"),
								},
								{
									value: translate("requests.fields.PHD"),
									label: translate("requests.fields.PHD"),
								},
								{
									value: translate("requests.fields.Other"),
									label: translate("requests.fields.Other"),
								},
							]}
						/>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.studyMajor")}
						name="studyMajor"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.gender")}
						name="gender"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select
							placeholder={translate("requests.fields.gender")}
							options={[
								{
									value: translate("common.male"),
									label: translate("common.male"),
								},
								{
									value: translate("common.female"),
									label: translate("common.female"),
								},
							]}
						/>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.contactOne")}
						name="contactOne"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.contactTwo")}
						name="contactTwo"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.identificationCard")}
						name="identificationCard"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.identificationID")}
						name="identificationID"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.tazkiraID")}
						name="tazkiraID"
					>
						<Input />
					</Form.Item>

					<Divider />

					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.CoachingCertificate")}
						name="hasCoachingCertificate"
					>
						<Switch />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.technicalLevel")}
						name="technicalLevel"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.ArbitrationCertificate")}
						name="hasArbitrationCertificate"
					>
						<Switch />
					</Form.Item>

					<Divider />

					<Form.Item
						style={{ marginBottom: 10, width: 200 }}
						label={translate("sport-majors.sport-major")}
						name="sport_major"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select {...selectProps} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: 200 }}
						label={translate("sport-styles.sport-style")}
						name="sport_style"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select {...sportStylesSelectProps} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: 200 }}
						label={translate("provinces.province")}
						name="province"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select {...provincesSelectProps} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.currentClubMembershipLabel")}
						name="currentClubMembership"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.nationalTeamMembershipLabel")}
						name="nationalTeamMembership"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.formerMembershipsLabel")}
						name="formerMemberships"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.currentAddress")}
						name="currentAddress"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.mainAddress")}
						name="mainAddress"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.honors")}
						name="honors"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Divider />

					<Space direction="vertical">
						<p>{translate("requests.titles.mandatoryFilesLable")}</p>
						<p style={{ color: "red" }}>{translate("common.fileSizeMsg")}</p>
					</Space>
					<Divider />

					<Form.Item label={translate("common.photo")}>
						<Form.Item
							name="photo"
							valuePropName="fileList"
							getValueProps={(data) => getValueProps(data, API_URL)}
							noStyle
						>
							<Upload.Dragger
								beforeUpload={(file: any, FileList: any) => {
									if (Number(file.size / 1024 / 1024) > 1) {
										message.error(translate("common.fileSizeMsg"));
										return Upload.LIST_IGNORE;
									} else {
										return true;
									}
								}}
								name="files"
								action={`${API_URL}/upload`}
								headers={{
									Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
								}}
								listType="picture"
								multiple={false}
								maxCount={1}
								{...uploadProps}
								accept=".png,.jpeg"
							>
								<p className="ant-upload-text">
									{translate("common.photoMsg")}
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Form.Item>

					<Form.Item label={translate("common.files")}>
						<Form.Item
							name="files"
							valuePropName="fileList"
							getValueProps={(data) => getValueProps(data, API_URL)}
							noStyle
						>
							<Upload.Dragger
								beforeUpload={(file: any, FileList: any) => {
									if (Number(file.size / 1024 / 1024) > 1) {
										message.error(translate("common.fileSizeMsg"));
										return Upload.LIST_IGNORE;
									} else {
										return true;
									}
								}}
								name="files"
								action={`${API_URL}/upload`}
								headers={{
									Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
								}}
								multiple
								// maxCount={7}
								{...uploadProps}
							>
								<p className="ant-upload-text">
									{translate("common.filesMsg")}
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Form.Item>

					<Divider />
					<Space direction="vertical">
						<Form.Item
							style={{ marginBottom: 10, width: "100%" }}
							label={translate("common.paymentReceiptNo")}
							name="paymentReceiptNo"
						>
							<Input />
						</Form.Item>

						<Form.Item label={translate("common.paymentReceipt")}>
							<Form.Item
								name="paymentReceipt"
								valuePropName="fileList"
								getValueProps={(data) => getValueProps(data, API_URL)}
								noStyle
							>
								<Upload.Dragger
									beforeUpload={(file: any, FileList: any) => {
										if (Number(file.size / 1024 / 1024) > 1) {
											message.error(translate("common.fileSizeMsg"));
											return Upload.LIST_IGNORE;
										} else {
											return true;
										}
									}}
									name="files"
									action={`${API_URL}/upload`}
									headers={{
										Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
									}}
									listType="picture"
									multiple={false}
									maxCount={1}
									{...uploadProps}
									accept=".png,.jpeg"
								>
									<p className="ant-upload-text">
										{translate("common.photoMsg")}
									</p>
								</Upload.Dragger>
							</Form.Item>
						</Form.Item>
					</Space>
				</Form>
			</Spin>
		</Create>
	);
};

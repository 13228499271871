import React, { useState } from "react";
import {
	ForgotPasswordPageProps,
	ForgotPasswordFormTypes,
} from "@pankod/refine-core";
import {
	Row,
	Col,
	Layout,
	Card,
	Typography,
	Form,
	Input,
	Button,
	LayoutProps,
	CardProps,
	FormProps,
	message,
} from "antd";
import {
	useTranslate,
	useRouterContext,
	useForgotPassword,
} from "@pankod/refine-core";

import { layoutStyles, containerStyles, titleStyles } from "../styles";
import axios from "axios";
import { API_URL } from "../../../constants";

type ResetPassworProps = ForgotPasswordPageProps<
	LayoutProps,
	CardProps,
	FormProps
>;

const { Text, Title } = Typography;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const ForgotPasswordPage: React.FC<ResetPassworProps> = ({
	loginLink,
	wrapperProps,
	contentProps,
	renderContent,
	formProps,
}) => {
	const [form] = Form.useForm<ForgotPasswordFormTypes>();
	const translate = useTranslate();
	const { Link } = useRouterContext();
	const [loading, setLoading] = useState(false);

	const { mutate: forgotPassword, isLoading } =
		useForgotPassword<ForgotPasswordFormTypes>();

	const CardTitle = (
		<Title level={3} style={titleStyles}>
			{translate("pages.forgotPassword.title", "Forgot your password?")}
		</Title>
	);

	const fieldValue = Form.useWatch("email", form);

	const handleSubmit = async () => {
		setLoading(true);

		var url: string = "";
		if (fieldValue?.includes("@") && fieldValue?.includes("com")) {
			url = `${API_URL}/api/auth/forgot-password`;
		} else {
			url = `${API_URL}/api/auth/forgot-password-mobile`;
		}

		return await axios
			.post(
				url,
				fieldValue?.includes("@") && fieldValue?.includes("com")
					? { email: fieldValue }
					: { contact: fieldValue }
			)
			.then((response) => {
				if (response?.data?.ok === false) {
					if (response?.data?.error === "user not found") {
						setLoading(false);
						return message.error(translate("pages.error.contactUserNotFound"));
					} else if (response?.data?.error === "user is blocked") {
						setLoading(false);
						return message.error(translate("pages.error.blockedAccount"));
					}
				} else {
					setLoading(false);
					return message.success(translate("common.resetPassMsg"));
				}
			})
			.catch((error) => {
				setLoading(false);
				return message.error(translate("common.errorOccurred"), error.response);
			});
	};

	const CardContent = (
		<Card
			title={CardTitle}
			headStyle={{ borderBottom: 0 }}
			style={containerStyles}
			{...(contentProps ?? {})}
		>
			<Form<ForgotPasswordFormTypes>
				layout="vertical"
				form={form}
				onFinish={handleSubmit}
				requiredMark={false}
				{...formProps}
			>
				<Form.Item
					name="email"
					label={translate("pages.forgotPassword.fields.emailorcontact")}
					rules={[{ required: true }]}
				>
					<Input
						size="large"
						placeholder={translate(
							"pages.forgotPassword.fields.emailorcontact"
						)}
					/>
				</Form.Item>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "12px",
					}}
				>
					{loginLink ?? (
						<Text
							style={{
								fontSize: 12,
								marginLeft: "auto",
							}}
						>
							{translate(
								"pages.register.buttons.haveAccount",
								"Have an account? "
							)}{" "}
							<Link
								style={{
									fontWeight: "bold",
								}}
								to="/login"
							>
								{translate("pages.login.signin", "Sign in")}
							</Link>
						</Text>
					)}
				</div>
				<Form.Item>
					<Button
						type="primary"
						size="large"
						htmlType="submit"
						loading={loading}
						block
					>
						{translate(
							"pages.forgotPassword.buttons.submit",
							"Send reset instructions"
						)}
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);

	return (
		<Layout style={layoutStyles} {...(wrapperProps ?? {})}>
			<Row
				justify="center"
				align="middle"
				style={{
					height: "100vh",
				}}
			>
				<Col xs={22}>
					{renderContent ? renderContent(CardContent) : CardContent}
				</Col>
			</Row>
		</Layout>
	);
};

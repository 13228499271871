import { useState, useContext } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	CrudFilters,
	HttpError,
	useMany,
	useExport,
} from "@pankod/refine-core";

import {
	List,
	Table,
	useTable,
	getDefaultSortOrder,
	FilterDropdown,
	Select,
	useSelect,
	Space,
	EditButton,
	DeleteButton,
	Row,
	Col,
	Card,
	Form,
	Input,
	Icons,
	Button,
	Tag,
	ImageField,
	Divider,
	Switch,
	Collapse,
	ErrorComponent,
	ShowButton,
	ExportButton,
	CreateButton,
} from "@pankod/refine-antd";

import {
	IRequest,
	IUser,
	ISportStyle,
	IRequestFilterVariables,
	ISportMajor,
	IProvince,
} from "interfaces";
import { API_URL } from "../../constants";
import { IsSuperAdmin } from "helpers/utilities";
import { LoginContext } from "contexts/authContext";

export const RequestsList: React.FC<IResourceComponentsProps> = () => {
	const { userInfo } = useContext(LoginContext);
	const translate = useTranslate();
	const { Panel } = Collapse;
	const [exportFilters, setExportFilters] = useState<CrudFilters>([]);

	let filters: CrudFilters = [];
	let provinceId;
	if (userInfo) {
		const { type } = JSON.parse(userInfo);
		if (type === translate("pages.login.fields.provinceAdmin")) {
			const { province } = JSON.parse(userInfo);
			provinceId = province?.id;
		}
	}

	const { tableProps, sorter, searchFormProps } = useTable<
		IRequest,
		HttpError,
		IRequestFilterVariables
	>({
		initialSorter: [
			{
				field: "id",
				order: "desc",
			},
		],
		metaData: {
			populate: ["sport_major", "sport_style", "province", "photo"],
		},
		initialFilter: [
			{
				field: "province.id",
				operator: "eq",
				value: provinceId || null,
			},
			{
				field: "isDraft",
				operator: "eq",
				value: false,
			},
		],
		onSearch: (params) => {
			const {
				name,
				lastName,
				fatherName,
				education,
				gender,
				contactOne,
				contactTwo,
				identificationCard,
				identificationID,
				hasArbitrationCertificate,
				hasCoachingCertificate,
				// technicalLevel,
				sport_major,
				sport_style,
				federationAdminApproval,
				provincialAdminApproval,
				sportmajorAdminApproval,
				currentClubMembership,
				formerMemberships,
				province,
			} = params;

			filters.push(
				{
					field: "name",
					operator: "contains",
					value: name,
				},
				{
					field: "lastName",
					operator: "contains",
					value: lastName,
				},
				{
					field: "fatherName",
					operator: "contains",
					value: fatherName,
				},
				{
					field: "education",
					operator: "contains",
					value: education,
				},
				{
					field: "gender",
					operator: "contains",
					value: gender,
				},
				{
					field: "contactOne",
					operator: "contains",
					value: contactOne,
				},
				{
					field: "contactTwo",
					operator: "contains",
					value: contactTwo,
				},
				{
					field: "identificationCard",
					operator: "contains",
					value: identificationCard,
				},
				{
					field: "identificationID",
					operator: "contains",
					value: identificationID,
				},
				{
					field: "currentClubMembership",
					operator: "contains",
					value: currentClubMembership,
				},
				{
					field: "formerMemberships",
					operator: "contains",
					value: formerMemberships,
				},
				{
					field: "sport_major.id",
					operator: "eq",
					value: sport_major,
				},
				{
					field: "sport_style.id",
					operator: "eq",
					value: sport_style,
				},
				{
					field: "province.id",
					operator: "eq",
					value: province,
				},

				{
					field: "federationAdminApproval",
					operator: "eq",
					value: federationAdminApproval,
				},
				{
					field: "provincialAdminApproval",
					operator: "eq",
					value: provincialAdminApproval,
				},
				{
					field: "sportmajorAdminApproval",
					operator: "eq",
					value: sportmajorAdminApproval,
				}
			);

			setExportFilters(filters);

			return filters;
		},
	});

	const { triggerExport, isLoading: exportLoading } = useExport<IRequest>({
		metaData: {
			populate: ["sport_major", "sport_style", "province", "photo"],
		},
		filters: exportFilters?.filter((e) => e.value !== undefined) || [],
		mapData: (item) => {
			return {
				"آی دی": item.id,
				"تاریخ ایجاد":
					new Date(item?.createdAt).toLocaleTimeString("fa-IR") +
					"  -  " +
					new Date(item?.createdAt).toLocaleDateString("fa-IR"),
				نام: item?.name,
				"نام خانوادگی": item?.lastName || "",
				"نام پدر": item?.fatherName || "",
				جنسیبت: item?.gender || "",
				"تاریخ تولد": item?.DOB || "",
				"محل تولد": item?.placeOfBirth || "",
				شغل: item?.job || "",
				"میزان تحصیلات": item?.education || "",
				"رشته تحصیلی": item?.studyMajor || "",
				"شماره تماس 1": item?.contactOne || "",
				"شماره تماس 2": item?.contactTwo || "",
				"نوع مدرک شناسایی": item?.identificationCard || "",
				"شماره مدرک": item?.identificationID || "",
				"شماره تذکره": item?.tazkiraID || "",
				"رشته ورزشی": item?.sport_major?.title,
				"سبک ورزشی": item?.sport_style?.title,
				استان: item?.province?.title,
				"آدرس سکونت فعلی": item?.currentAddress || "",
				"آدرس سکونت اصلی": item?.mainAddress || "",
				"مدرک مربیگری": item?.hasCoachingCertificate ? "دارد" : "ندارد",
				"درجه فنی": item?.technicalLevel || "",
				"مدرک داوری": item?.hasArbitrationCertificate ? "دارد" : "ندارد",
				"تاییدیه رسایت فدراسیون": item?.federationAdminApproval
					? "دارد"
					: "ندارد",
				"تاییدیه نماینده استانی": item?.provincialAdminApproval
					? "دارد"
					: "ندارد",
				"تاییدیه نماینده رشته ورزشی": item?.sportmajorAdminApproval
					? "دارد"
					: "ندارد",
				"عضویت باشگاه فعلی": item?.currentMembershipClub || "",
				"عضویت و سمت های قبلی": item?.formerMemberships || "",
				افتخارات: item?.honors || "",
			};
		},
	});

	if (IsSuperAdmin() === false) {
		if (userInfo) {
			const { type } = JSON.parse(userInfo);
			if (type === translate("pages.login.fields.user")) {
				return <ErrorComponent />;
			}
		}
	}

	return (
		<div>
			<Collapse>
				<Panel
					style={{ marginBottom: 10 }}
					header={translate("common.filters")}
					key="1"
				>
					<Filter formProps={searchFormProps} />
				</Panel>
			</Collapse>

			<List
				title={
					translate("requests.requests") +
					" " +
					`(${tableProps?.dataSource?.length || 0})`
				}
				canCreate={
					userInfo && JSON.parse(userInfo).type === "pages.login.fields.user"
						? true
						: false
				}
				headerButtons={
					<>
						<ExportButton onClick={triggerExport} loading={exportLoading} />
						<CreateButton />
					</>
				}
			>
				<Table {...tableProps} scroll={{ x: 700 }} rowKey="id">
					<Table.Column
						dataIndex="createdAt"
						key="createdAt"
						width={150}
						title={translate("requests.fields.createdAt")}
						render={(value) => {
							return (
								new Date(value).toLocaleTimeString("fa-IR") +
								"  -  " +
								new Date(value).toLocaleDateString("fa-IR")
							);
						}}
					/>
					<Table.Column
						dataIndex="isDraft"
						key="isDraft"
						title={translate("common.status")}
						width={150}
						render={(value) => {
							return (
								<Tag color={value === true ? "red" : "green"}>
									{value === true
										? translate("common.draft")
										: translate("common.sent")}
								</Tag>
							);
						}}
					/>
					<Table.Column
						dataIndex={"photo"}
						align="center"
						title={translate("common.photo")}
						width={150}
						render={(value) => {
							return value ? (
								<ImageField
									value={API_URL + value[0].url}
									alt={value[0]?.name}
									title={value[0]?.name}
									width={48}
									preview={{ mask: <></> }}
								/>
							) : (
								<span>---</span>
							);
						}}
					/>
					<Table.Column
						dataIndex="name"
						key="name"
						title={translate("requests.fields.name")}
						defaultSortOrder={getDefaultSortOrder("name", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
						width={150}
					/>
					<Table.Column
						dataIndex="lastName"
						key="lastName"
						width={150}
						title={translate("requests.fields.lastName")}
						defaultSortOrder={getDefaultSortOrder("lastName", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
					/>
					<Table.Column
						dataIndex="fatherName"
						key="fatherName"
						title={translate("requests.fields.fatherName")}
						defaultSortOrder={getDefaultSortOrder("fatherName", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
						width={150}
					/>
					<Table.Column
						dataIndex="gender"
						key="gender"
						width={150}
						title={translate("requests.fields.gender")}
					/>
					<Table.Column
						dataIndex="DOB"
						key="DOB"
						width={150}
						title={translate("requests.fields.DOB")}
						render={(value) => {
							return new Date(value).toLocaleDateString("fa-IR");
						}}
					/>
					<Table.Column
						dataIndex="placeOfBirth"
						key="placeOfBirth"
						title={translate("requests.fields.placeOfBirth")}
						width={150}
					/>
					<Table.Column
						dataIndex="job"
						key="job"
						title={translate("requests.fields.job")}
						width={150}
					/>
					<Table.Column
						dataIndex="education"
						key="education"
						width={150}
						title={translate("requests.fields.education")}
					/>
					<Table.Column
						dataIndex="studyMajor"
						key="studyMajor"
						title={translate("requests.fields.studyMajor")}
						width={150}
					/>
					<Table.Column
						dataIndex="contactOne"
						key="contactOne"
						title={translate("requests.fields.contactOne")}
						width={150}
					/>
					<Table.Column
						dataIndex="contactTwo"
						key="contactTwo"
						title={translate("requests.fields.contactTwo")}
						width={150}
					/>
					<Table.Column
						dataIndex="identificationCard"
						key="identificationCard"
						title={translate("requests.fields.identificationCard")}
						width={150}
					/>
					<Table.Column
						dataIndex="identificationID"
						key="identificationID"
						title={translate("requests.fields.identificationID")}
						width={150}
					/>
					<Table.Column
						dataIndex="tazkiraID"
						key="tazkiraID"
						title={translate("requests.fields.tazkiraID")}
						width={150}
					/>
					<Table.Column
						key="[sport_major][id]"
						dataIndex={["sport_major", "title"]}
						title={translate("sport-majors.sport-major")}
						width={150}
					/>
					<Table.Column
						key="[sport_style][id]"
						dataIndex={["sport_style", "title"]}
						title={translate("sport-styles.sport-style")}
						width={150}
					/>
					<Table.Column
						key="[province][id]"
						dataIndex={["province", "title"]}
						title={translate("provinces.province")}
						width={150}
					/>
					<Table.Column
						dataIndex="currentAddress"
						key="currentAddress"
						title={translate("requests.fields.currentAddress")}
						width={300}
					/>
					<Table.Column
						dataIndex="mainAddress"
						key="mainAddress"
						title={translate("requests.fields.mainAddress")}
						width={300}
					/>
					<Table.Column
						dataIndex="honors"
						key="honors"
						title={translate("requests.fields.honors")}
						width={350}
					/>
					<Table.Column
						dataIndex="hasCoachingCertificate"
						key="hasCoachingCertificate"
						title={translate("requests.fields.CoachingCertificate")}
						width={150}
						defaultSortOrder={getDefaultSortOrder("name", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
						render={(value) => {
							return (
								<Tag color={value === true ? "green" : "red"}>
									{value === true
										? translate("common.has")
										: translate("common.hasNot")}
								</Tag>
							);
						}}
					/>
					<Table.Column
						dataIndex="technicalLevel"
						key="technicalLevel"
						title={translate("requests.fields.technicalLevel")}
						width={150}
					/>
					<Table.Column
						dataIndex="hasArbitrationCertificate"
						key="hasArbitrationCertificate"
						title={translate("requests.fields.ArbitrationCertificate")}
						width={150}
						defaultSortOrder={getDefaultSortOrder("name", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
						render={(value) => {
							return (
								<Tag color={value === true ? "green" : "red"}>
									{value === true
										? translate("common.has")
										: translate("common.hasNot")}
								</Tag>
							);
						}}
					/>
					<Table.Column
						dataIndex="federationAdminApproval"
						key="federationAdminApproval"
						title={translate("requests.fields.federationAdminApproval")}
						width={200}
						defaultSortOrder={getDefaultSortOrder("name", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
						render={(value) => {
							return (
								<Tag color={value === true ? "green" : "red"}>
									{value === true
										? translate("common.approved")
										: translate("common.notApproved")}
								</Tag>
							);
						}}
					/>
					<Table.Column
						dataIndex="provincialAdminApproval"
						key="provincialAdminApproval"
						title={translate("requests.fields.provincialAdminApproval")}
						width={200}
						defaultSortOrder={getDefaultSortOrder("name", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
						render={(value) => {
							return (
								<Tag color={value === true ? "green" : "red"}>
									{value === true
										? translate("common.approved")
										: translate("common.notApproved")}
								</Tag>
							);
						}}
					/>
					<Table.Column
						dataIndex="sportmajorAdminApproval"
						key="sportmajorAdminApproval"
						title={translate("requests.fields.sportmajorAdminApproval")}
						width={200}
						defaultSortOrder={getDefaultSortOrder("name", sorter)}
						sorter={{ multiple: 2 }}
						showSorterTooltip={false}
						render={(value) => {
							return (
								<Tag color={value === true ? "green" : "red"}>
									{value === true
										? translate("common.approved")
										: translate("common.notApproved")}
								</Tag>
							);
						}}
					/>
					<Table.Column
						dataIndex="currentClubMembership"
						key="currentClubMembership"
						title={translate("requests.fields.currentClubMembership")}
						width={200}
					/>
					<Table.Column
						dataIndex="formerMemberships"
						key="formerMemberships"
						title={translate("requests.fields.formerMemberships")}
						width={230}
					/>

					<Table.Column<{ id: string }>
						width={130}
						fixed={"right"}
						title={translate("table.actions")}
						dataIndex="actions"
						render={(_, record) => (
							<Space>
								<ShowButton hideText size="small" recordItemId={record.id} />
								{(userInfo &&
									JSON.parse(userInfo).type !==
										translate("pages.login.fields.admin")) ||
									(userInfo &&
										JSON.parse(userInfo).type !==
											translate("pages.login.fields.user") && (
											<>
												<EditButton
													hideText
													size="small"
													recordItemId={record.id}
												/>
												<DeleteButton
													hideText
													size="small"
													recordItemId={record.id}
												/>
											</>
										))}
							</Space>
						)}
					/>
				</Table>
			</List>
		</div>
	);
};

const Filter: React.FC<{ formProps: any }> = ({ formProps }) => {
	const { userInfo } = useContext(LoginContext);

	const translate = useTranslate();
	const { selectProps: sportMajorSelectProps } = useSelect<ISportMajor>({
		resource: "sport-majors",
	});

	const { selectProps: sportStyleSelectProps } = useSelect<ISportStyle>({
		resource: "sport-styles",
	});

	const { selectProps: ProvinceSelectProps } = useSelect<IProvince>({
		resource: "provinces",
	});

	return (
		<Form layout="inline" {...formProps}>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.name")}
				name="name"
			>
				<Input placeholder={translate("requests.fields.name")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.lastName")}
				name="lastName"
			>
				<Input placeholder={translate("requests.fields.lastName")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.fatherName")}
				name="fatherName"
			>
				<Input placeholder={translate("requests.fields.fatherName")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.education")}
				name="education"
			>
				<Input placeholder={translate("requests.fields.education")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.gender")}
				name="gender"
			>
				<Input placeholder={translate("requests.fields.gender")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.contactOne")}
				name="contactOne"
			>
				<Input placeholder={translate("requests.fields.contactOne")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.contactTwo")}
				name="contactTwo"
			>
				<Input placeholder={translate("requests.fields.contactTwo")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.identificationCard")}
				name="identificationCard"
			>
				<Input placeholder={translate("requests.fields.identificationCard")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.identificationID")}
				name="identificationID"
			>
				<Input placeholder={translate("requests.fields.identificationID")} />
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.currentClubMembership")}
				name="currentClubMembership"
			>
				<Input
					placeholder={translate("requests.fields.currentClubMembership")}
				/>
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.formerMemberships")}
				name="formerMemberships"
			>
				<Input placeholder={translate("requests.fields.formerMemberships")} />
			</Form.Item>
			<Divider />
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("sport-majors.sport-major")}
				name="sport_major"
			>
				<Select
					{...sportMajorSelectProps}
					allowClear
					placeholder={translate("sport-majors.sport-major")}
				/>
			</Form.Item>
			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("sport-styles.sport-style")}
				name="sport_style"
			>
				<Select
					{...sportStyleSelectProps}
					allowClear
					placeholder={translate("sport-styles.sport-style")}
				/>
			</Form.Item>

			{userInfo &&
				JSON.parse(userInfo).type !==
					translate("pages.login.fields.provinceAdmin") && (
					<Form.Item
						style={{ marginBottom: 10, width: 200 }}
						label={translate("provinces.province")}
						name="province"
					>
						<Select
							{...ProvinceSelectProps}
							allowClear
							placeholder={translate("provinces.province")}
						/>
					</Form.Item>
				)}

			<Divider />
			{/* <Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.CoachingCertificate")}
				name="hasCoachingCertificate"
			>
				<Switch />
			</Form.Item>

			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.technicalLevel")}
				name="technicalLevel"
			>
				<Input placeholder={translate("requests.fields.technicalLevel")} />
			</Form.Item>

			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.ArbitrationCertificate")}
				name="hasArbitrationCertificate"
			>
				<Switch />
			</Form.Item>
			<Divider /> */}

			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.federationAdminApproval")}
				name="federationAdminApproval"
			>
				<Switch />
			</Form.Item>

			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.sportmajorAdminApproval")}
				name="sportmajorAdminApproval"
			>
				<Switch />
			</Form.Item>

			<Form.Item
				style={{ marginBottom: 10 }}
				label={translate("requests.fields.provincialAdminApproval")}
				name="provincialAdminApproval"
			>
				<Switch />
			</Form.Item>

			<Divider />
			<Form.Item>
				<Button htmlType="submit" type="primary">
					{translate("common.filter")}
				</Button>
			</Form.Item>
		</Form>
	);
};

import { useState, useEffect, useContext } from "react";
import { Col, Icons, Row, Statistic, Typography } from "@pankod/refine-antd";
import "./index.css";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { TOKEN_KEY, API_URL } from "../../constants";
import axios from "axios";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { LoginContext } from "contexts/authContext";
import { Link } from "@pankod/refine-react-router-v6";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

export default function Dashboard() {
	const [federationReqs, setFederationReqs] = useState(0);
	const [provinceReqs, setProvinceReqs] = useState(0);
	const [sportMajorReqs, setSportMajorReqs] = useState(0);
	const [males, setMales] = useState(0);
	const [females, setFemales] = useState(0);
	const [users, setUsers] = useState(0);
	const [provinces, setProvinces] = useState(0);
	const [requests, setRequests] = useState(0);
	const [sportMajors, setSportMajors] = useState(0);
	const translate = useTranslate();
	const { userInfo } = useContext(LoginContext);
	const { push } = useNavigation();

	useEffect(() => {
		if (userInfo && JSON.parse(userInfo).type == "ورزشکار") {
			return push("/myrequests");
		}

		const fetchData = async () => {
			const token = localStorage.getItem(TOKEN_KEY);
			if (!token) {
				return Promise.reject();
			}

			const res = await axios.get(
				`${API_URL}/api/requests?filters[federationAdminApproval][$eq]=true&fields[0]=id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setFederationReqs(res?.data?.data?.length);

			const res2 = await axios.get(
				`${API_URL}/api/requests?filters[provincialAdminApproval][$eq]=true&fields[0]=id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setProvinceReqs(res2?.data?.data?.length);

			const res3 = await axios.get(
				`${API_URL}/api/requests?filters[sportmajorAdminApproval][$eq]=true&fields[0]=id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setSportMajorReqs(res3?.data?.data?.length);

			const res4 = await axios.get(
				`${API_URL}/api/requests?filters[gender][$contains]=مذکر&fields[0]=id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setMales(res4?.data?.data?.length);

			const res5 = await axios.get(
				`${API_URL}/api/requests?filters[gender][$contains]=مونث&fields[0]=id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setFemales(res5?.data?.data?.length);

			const res6 = await axios.get(
				`${API_URL}/api/users?filters[type][$contains]=ورزشکار&fields[0]=id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setUsers(res6?.data?.length);

			const res7 = await axios.get(`${API_URL}/api/provinces?fields[0]=id`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setProvinces(res7?.data?.data?.length);

			const res8 = await axios.get(`${API_URL}/api/requests?fields[0]=id`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setRequests(res8?.data?.data?.length);

			const res9 = await axios.get(`${API_URL}/api/sport-majors?fields[0]=id`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setSportMajors(res9?.data?.data?.length);
		};

		fetchData();
	}, [userInfo]);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
			},
		},
	};

	const labels = [""];

	const data = {
		labels,
		datasets: [
			{
				label: translate("requests.fields.federationAdminApproval") || "",
				data: labels.map(() => federationReqs),
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
			{
				label: translate("requests.fields.provincialAdminApproval") || "",
				data: labels.map(() => provinceReqs),
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
			{
				label: translate("requests.fields.sportmajorAdminApproval") || "",
				data: labels.map(() => sportMajorReqs),
				backgroundColor: "rgba(80, 99, 132, 0.5)",
			},
		],
	};

	const genderData = {
		labels: [translate("common.male") || "", translate("common.female")] || "",
		datasets: [
			{
				label: "",
				data: [males, females],
				backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
				borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
				borderWidth: 1,
			},
		],
	};

	return (
		<div>
			{userInfo && JSON.parse(userInfo).type == "ورزشکار" ? (
				<></>
			) : (
				<>
					<Row gutter={[24, 24]} style={{ marginBottom: 20 }}>
						<Col xs={24} sm={12} md={6}>
							<div className="dashboard-card">
								<Link to={"/requests"}>
									<Statistic
										title={translate("sider.requests")}
										value={requests}
										prefix={<Icons.AuditOutlined />}
									/>
								</Link>
							</div>
						</Col>

						<Col xs={24} sm={12} md={6}>
							<div className="dashboard-card">
								<Link to={"/users"}>
									<Statistic
										title={translate("pages.login.fields.users")}
										value={users}
										prefix={<Icons.UserOutlined />}
									/>
								</Link>
							</div>
						</Col>

						<Col xs={24} sm={12} md={6}>
							<div className="dashboard-card">
								<Link to={"/provinces"}>
									<Statistic
										title={translate("sider.provinces")}
										value={provinces}
										prefix={<Icons.UngroupOutlined />}
									/>
								</Link>
							</div>
						</Col>

						<Col xs={24} sm={12} md={6}>
							<div className="dashboard-card">
								<Link to={"/sport-majors"}>
									<Statistic
										title={translate("sider.sport-majors")}
										value={sportMajors}
										prefix={<Icons.PicCenterOutlined />}
									/>
								</Link>
							</div>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={12} md={6}>
							<div className="dashboard-card">
								<Pie data={genderData} />
							</div>
						</Col>
						<Col xs={24} sm={12} md={18} style={{ height: "30%" }}>
							<div className="dashboard-card">
								<Bar options={options} data={data} />
							</div>
						</Col>
					</Row>
				</>
			)}
		</div>
	);
}

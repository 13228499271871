import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
} from "@pankod/refine-core";

import {
	Create,
	Form,
	Input,
	Icons,
	useForm,
	ErrorComponent,
} from "@pankod/refine-antd";

import { ISportMajor } from "interfaces";
import { IsSuperAdmin } from "helpers/utilities";

export const SportMajorCreate: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();
	const { formProps, saveButtonProps } = useForm<ISportMajor>();

	if (IsSuperAdmin() === false) {
		return <ErrorComponent />;
	}

	return (
		<Create
			saveButtonProps={saveButtonProps}
			goBack={
				currentLocale === "en" ? (
					<Icons.ArrowLeftOutlined />
				) : (
					<Icons.ArrowRightOutlined />
				)
			}
		>
			<Form {...formProps} layout="vertical">
				<Form.Item
					wrapperCol={{ span: 14 }}
					label={translate("sport-majors.fields.title")}
					name="title"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Create>
	);
};

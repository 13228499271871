import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL } from "../../constants";
import axios from "axios";
import { useParams } from "@pankod/refine-react-router-v6";
import {
	Button,
	Card,
	Descriptions,
	ImageField,
	Spin,
	Tag,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";

function UserInfo() {
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
	const translate = useTranslate();
	const { push } = useNavigation();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			axios
				.get(`${API_URL}/api/requests/${id}?populate=*`)
				.then(function (response) {
					setData(response?.data?.data?.attributes);
					setLoading(false);
				})
				.catch(function (error) {
					console.log(error);
					setLoading(false);
				});
		};

		fetchData();
	}, []);

	return (
		<Card
			title={translate("common.userInfo")}
			bordered={false}
			style={{ padding: 20 }}
		>
			<Descriptions bordered>
				{loading ? (
					<Spin />
				) : (
					<>
						{data?.photo?.data.length > 0 && (
							<Descriptions.Item label={translate("common.photo")}>
								<ImageField
									value={API_URL + data?.photo?.data[0]?.attributes.url}
									alt={data?.photo?.data[0]?.attributes.url}
									title={data?.photo?.data[0]?.attributes.url}
									width={48}
									preview={{ mask: <></> }}
								/>
							</Descriptions.Item>
						)}
						<Descriptions.Item label={translate("requests.fields.name")}>
							{data?.name}
						</Descriptions.Item>
						<Descriptions.Item label={translate("requests.fields.lastName")}>
							{data?.lastName}
						</Descriptions.Item>
						<Descriptions.Item label={translate("requests.fields.fatherName")}>
							{data?.fatherName}
						</Descriptions.Item>
						<Descriptions.Item label={translate("requests.fields.DOB")}>
							{data?.DOB}
						</Descriptions.Item>
						<Descriptions.Item
							label={translate("requests.fields.placeOfBirth")}
						>
							{data?.placeOfBirth}
						</Descriptions.Item>
						<Descriptions.Item
							label={translate("requests.fields.CoachingCertificate")}
						>
							<Tag
								color={data?.hasCoachingCertificate === true ? "green" : "red"}
							>
								{data?.hasCoachingCertificate === true
									? translate("common.has")
									: translate("common.hasNot")}
							</Tag>
						</Descriptions.Item>
						<Descriptions.Item
							label={translate("requests.fields.technicalLevel")}
						>
							{data?.technicalLevel}
						</Descriptions.Item>
						<Descriptions.Item
							label={translate("requests.fields.ArbitrationCertificate")}
						>
							<Tag
								color={
									data?.hasArbitrationCertificate === true ? "green" : "red"
								}
							>
								{data?.hasArbitrationCertificate === true
									? translate("common.has")
									: translate("common.hasNot")}
							</Tag>
						</Descriptions.Item>
						<Descriptions.Item label={translate("sport-majors.sport-major")}>
							{data?.sport_major?.data?.attributes?.title}
						</Descriptions.Item>
						<Descriptions.Item label={translate("sport-styles.sport-style")}>
							{data?.sport_style?.data?.attributes?.title}
						</Descriptions.Item>
						<Descriptions.Item label={translate("provinces.province")}>
							{data?.province?.title}
						</Descriptions.Item>
					</>
				)}
			</Descriptions>

			<Button
				onClick={() => push("/")}
				type="primary"
				style={{ marginTop: 10 }}
			>
				{translate("pages.error.backHome")}
			</Button>
		</Card>
	);
}

export default UserInfo;

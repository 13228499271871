import { useEffect, useState } from "react";
import { AntdInferencer } from "@pankod/refine-inferencer/antd";

import { Authenticated, Refine } from "@pankod/refine-core";
import {
	notificationProvider,
	ReadyPage,
	ErrorComponent,
	ConfigProvider,
} from "@pankod/refine-antd";
import { LoginPage } from "pages/Auth/login";
import "@pankod/refine-antd/dist/reset.css";
import "styles.css";

import { PostList, PostCreate, PostEdit } from "pages/posts";
import { RequestsList, RequestCreate, RequestEdit } from "pages/requests";
import { MyRequests } from "pages/my-requests";
import {
	SportMajorList,
	SportMajorCreate,
	SportMajorEdit,
} from "pages/sport-majors";
import {
	SportStyleList,
	SportStyleCreate,
	SportStyleEdit,
} from "pages/sport-styles";
import { ProvinceList, ProvinceCreate, ProvinceEdit } from "pages/provinces";
import { UsersList, UserCreate, UserEdit } from "pages/users";

import { DataProvider } from "@pankod/refine-strapi-v4";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { ColorModeContextProvider } from "contexts";
import {
	Title,
	Header,
	Sider,
	Footer,
	Layout,
	OffLayoutArea,
} from "components/layout";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
import { AuthPage } from "pages/Auth";
import { LoginProvider } from "./contexts/authContext";

import "dayjs/locale/zh-cn";
import locale from "antd/locale/fa_IR";

import { Icons } from "@pankod/refine-antd";
import Dashboard from "pages/dashboard";
import { RequestShow } from "pages/requests/show";
import { NotificationList, NotificationSingleList } from "pages/notifications";
import UserInfo from "pages/public/userInfo";

const {
	ProfileOutlined,
	BlockOutlined,
	BuildOutlined,
	UserOutlined,
	UsergroupAddOutlined,
} = Icons;

function App() {
	const { t, i18n } = useTranslation();

	const i18nProvider = {
		translate: (key: string, params: object) => t(key, params),
		changeLocale: (lang: string) => i18n.changeLanguage(lang),
		getLocale: () => i18n.language,
	};

	useEffect(() => {
		i18n.changeLanguage("fa");
	}, []);

	const AuthenticatedNotifications = () => {
		return (
			<Authenticated>
				<NotificationList />
			</Authenticated>
		);
	};

	const AuthenticatedSingleNotification = () => {
		return (
			<Authenticated>
				<NotificationSingleList />
			</Authenticated>
		);
	};

	return (
		<LoginProvider>
			<ColorModeContextProvider>
				<ConfigProvider direction={"rtl"} locale={locale}>
					<Refine
						authProvider={authProvider}
						dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
						LoginPage={LoginPage}
						notificationProvider={notificationProvider}
						ReadyPage={ReadyPage}
						catchAll={<ErrorComponent />}
						resources={[
							{
								name: "myrequests",
								options: {
									label: t("sider.myRequests", "Requests"),
								},
								icon: <ProfileOutlined />,
								list: MyRequests,
							},
							{
								name: "requests",
								options: {
									label: t("sider.requests", "Requests"),
								},
								icon: <ProfileOutlined />,
								list: RequestsList,
								create: RequestCreate,
								edit: RequestEdit,
								show: RequestShow,
							},
							{
								name: "provinces",
								options: {
									label: t("sider.provinces", "Provinces"),
								},
								icon: <BuildOutlined />,
								list: ProvinceList,
								create: ProvinceCreate,
								edit: ProvinceEdit,
							},
							{
								name: "sport-majors",
								options: {
									label: t("sider.sport-majors", "Sport majors"),
								},
								icon: <BuildOutlined />,
								list: SportMajorList,
								create: SportMajorCreate,
								edit: SportMajorEdit,
							},
							{
								name: "sport-styles",
								options: {
									label: t("sider.sport-styles", "Sport styles"),
								},
								icon: <BlockOutlined />,
								list: SportStyleList,
								create: SportStyleCreate,
								edit: SportStyleEdit,
							},
							{
								name: "users",
								options: {
									label: t("sider.users", "Users"),
								},
								icon: <UsergroupAddOutlined />,
								list: UsersList,
								create: UserCreate,
								edit: UserEdit,
							},
						]}
						Title={Title}
						Header={Header}
						Sider={Sider}
						Footer={Footer}
						Layout={Layout}
						OffLayoutArea={OffLayoutArea}
						DashboardPage={Dashboard}
						routerProvider={{
							...routerProvider,
							routes: [
								{
									path: "/register",
									element: <AuthPage type="register" />,
								},
								{
									path: "/forgot-password",
									element: <AuthPage type="forgotPassword" />,
								},
								{
									path: "/update-password",
									element: <AuthPage type="updatePassword" />,
								},
								{
									path: "/notifications",
									element: <AuthenticatedNotifications />,
									layout: true,
								},
								{
									path: "/notifications/:id",
									element: <AuthenticatedSingleNotification />,
									layout: true,
								},
								{
									path: "/user-info/:id",
									element: <UserInfo />,
								},
							],
						}}
						i18nProvider={i18nProvider}
					/>
				</ConfigProvider>
			</ColorModeContextProvider>
		</LoginProvider>
	);
}

export default App;

import React from "react";
import {
	RegisterPageProps,
	RegisterFormTypes,
	LoginFormTypes,
	useNotification,
	useNavigation,
} from "@pankod/refine-core";
import {
	Row,
	Col,
	Layout,
	Card,
	Typography,
	Form,
	Input,
	Button,
	LayoutProps,
	CardProps,
	FormProps,
	message,
	InputNumber,
} from "antd";
import { useTranslate, useRouterContext, useLogin } from "@pankod/refine-core";

import { layoutStyles, containerStyles, titleStyles } from "../styles";
import { AuthHelper } from "helpers/auth";
import { API_URL } from "../../../constants";
import { isNumber } from "helpers/utilities";

const { Text, Title } = Typography;

type RegisterProps = RegisterPageProps<LayoutProps, CardProps, FormProps>;

/**
 * **refine** has register page form which is served on `/register` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#register} for more details.
 */
export const RegisterPage: React.FC<RegisterProps> = ({
	loginLink,
	wrapperProps,
	contentProps,
	renderContent,
	formProps,
}) => {
	const [form] = Form.useForm<RegisterFormTypes>();
	const translate = useTranslate();
	const { Link } = useRouterContext();
	const strapiAuthHelper = AuthHelper(API_URL + "/api");
	const { open } = useNotification();
	const { push } = useNavigation();

	const { mutate: login } = useLogin<LoginFormTypes>();

	const CardTitle = (
		<Title level={3} style={titleStyles}>
			{translate("pages.register.title", "Sign up for your account")}
		</Title>
	);

	const onFinish = async (values: any) => {
		const { email, username, password, contact } = values;
		if (!isNumber(contact)) {
			return message.error(translate("common.isNumberError"));
		}
		await strapiAuthHelper
			.register(email, username, password, contact)
			.then(function (res) {
				return Promise.resolve().then(() => {
					// message.success(translate("common.signupMsg"));
					// push("/login");
					// return;
					login(values);
				});
			})
			.catch(function (error) {
				if (error.response) {
					if (
						error?.response?.data?.error?.message ===
						"Email or Username are already taken"
					) {
						return open?.({
							type: "error",
							message: translate(
								"pages.error.emailAlreadyTaken",
								"Email or Username are already taken"
							),
						});
					}

					if (
						error?.response?.data?.error?.message ===
						"This attribute must be unique"
					) {
						return open?.({
							type: "error",
							message: translate(
								"pages.error.uniquePhoneNumber",
								"Phone number is already taken"
							),
						});
					}

					return open?.({
						type: "error",
						message: error?.response?.data?.error?.message,
					});
				}
			});
	};

	const CardContent = (
		<Card
			title={CardTitle}
			headStyle={{ borderBottom: 0 }}
			style={containerStyles}
			{...(contentProps ?? {})}
		>
			<Form<RegisterFormTypes>
				layout="vertical"
				form={form}
				onFinish={onFinish}
				requiredMark={false}
				{...formProps}
			>
				<Form.Item
					name="username"
					label={translate("users.fields.username", "Email")}
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input
						size="large"
						placeholder={translate("pages.register.fields.email", "Email")}
					/>
				</Form.Item>
				<Form.Item
					name="email"
					label={translate("pages.register.fields.email", "Email")}
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
						{
							type: "email",
							message: translate(
								"pages.register.errors.validEmail",
								"Invalid email address"
							),
						},
					]}
				>
					<Input
						size="large"
						placeholder={translate("pages.register.fields.email", "Email")}
					/>
				</Form.Item>
				<Form.Item
					name="password"
					label={translate("pages.register.fields.password", "Password")}
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
					style={{ marginBottom: "12px" }}
				>
					<Input type="password" placeholder="●●●●●●●●" size="large" />
				</Form.Item>

				<Form.Item
					name="contact"
					label={translate("users.fields.contact", "Contact")}
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
					style={{ marginBottom: "12px" }}
				>
					<Input size="large" />
				</Form.Item>

				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "12px",
					}}
				>
					{loginLink ?? (
						<Text
							style={{
								fontSize: 12,
								marginLeft: "auto",
							}}
						>
							{translate(
								"pages.register.buttons.haveAccount",
								"Have an account?"
							)}{" "}
							<Link
								style={{
									fontWeight: "bold",
								}}
								to="/login"
							>
								{translate("pages.login.signin", "Sign in")}
							</Link>
						</Text>
					)}
				</div>

				<Form.Item>
					<Button type="primary" size="large" htmlType="submit" block>
						{translate("pages.register.buttons.submit", "Sign up")}
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);

	return (
		<Layout style={layoutStyles} {...(wrapperProps ?? {})}>
			<Row
				justify="center"
				align="middle"
				style={{
					height: "100vh",
				}}
			>
				<Col xs={22}>
					{renderContent ? renderContent(CardContent) : CardContent}
				</Col>
			</Row>
		</Layout>
	);
};

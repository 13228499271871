import { useContext, useState } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	CrudFilters,
	HttpError,
	useExport,
} from "@pankod/refine-core";

import {
	List,
	Table,
	useTable,
	Space,
	EditButton,
	Row,
	Col,
	Card,
	DeleteButton,
	Form,
	Input,
	Icons,
	Button,
	Select,
	Switch,
	ImageField,
	getDefaultSortOrder,
	useSelect,
	ErrorComponent,
	ExportButton,
	CreateButton,
} from "@pankod/refine-antd";

import { IProvince, IUser, IUserFilterVariables } from "interfaces";

import { IsSuperAdmin } from "../../helpers/utilities";
import { LoginContext } from "contexts/authContext";
import { API_URL } from "../../constants";

export const UsersList: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();
	const { userInfo } = useContext(LoginContext);
	const [exportFilters, setExportFilters] = useState<CrudFilters>([]);

	let filters: CrudFilters = [];
	const filterss: CrudFilters = [];
	let provinceId;
	let userType = "";
	if (userInfo) {
		const { type } = JSON.parse(userInfo);
		userType = type;
		if (type === translate("pages.login.fields.provinceAdmin")) {
			const { province } = JSON.parse(userInfo);
			provinceId = province?.id;
		}
	}

	if (userInfo && userType !== translate("pages.login.fields.admin")) {
		if (userInfo && userType !== translate("pages.login.fields.user")) {
			filterss.push({
				field: "type",
				operator: "ne",
				value: translate("pages.login.fields.user"),
			});
		}
	}

	if (userInfo && userType === translate("pages.login.fields.provinceAdmin")) {
		filterss.push({
			field: "province.id",
			operator: "eq",
			value: provinceId || null,
		});
	}

	const { tableProps, sorter, searchFormProps } = useTable<
		IUser,
		HttpError,
		IUserFilterVariables
	>({
		initialSorter: [
			{
				field: "id",
				order: "desc",
			},
		],
		metaData: {
			populate: ["province", "photo"],
		},
		initialFilter: filterss,
		onSearch: (params) => {
			const { username, contact, email, type, blocked, province } = params;

			filters.push(
				{
					field: "username",
					operator: "contains",
					value: username,
				},
				{
					field: "province.id",
					operator: "eq",
					value: province,
				},
				{
					field: "email",
					operator: "contains",
					value: email,
				},
				{
					field: "contact",
					operator: "contains",
					value: contact,
				},
				{
					field: "blocked",
					operator: "eq",
					value: blocked,
				},
				{
					field: "type",
					operator: "contains",
					value: type,
				}
			);

			if (filters.find((e) => e.value === translate("common.all"))) {
				filters.pop();
				filters.push({
					field: "type",
					operator: "contains",
					value: undefined,
				});
			}

			setExportFilters(filters);

			return filters;
		},
	});

	const { triggerExport, isLoading: exportLoading } = useExport<IUser>({
		metaData: {
			populate: ["province", "photo"],
		},
		filters: exportFilters?.filter((e) => e.value !== undefined) || [],
		mapData: (item) => {
			return {
				"آی دی": item.id,
				"نام و نام خانوادگی": item.username,
				ایمیل: item.email,
				نوع: item.type,
				تماس: item.contact,
				استان: item?.province?.title,
			};
		},
	});

	if (IsSuperAdmin() === false) {
		if (userInfo) {
			const { type } = JSON.parse(userInfo);
			if (
				!(
					type === translate("pages.login.fields.provinceAdmin") ||
					type === translate("pages.login.fields.federationAdmin")
				)
			) {
				return <ErrorComponent />;
			}
		}
	}

	return (
		<Row gutter={[16, 16]}>
			<Col lg={6} xs={24}>
				<Card title={translate("common.filters")}>
					<Filter formProps={searchFormProps} />
				</Card>
			</Col>
			<Col lg={18} xs={24}>
				<List
					title={
						translate("users.users") +
						" " +
						`(${tableProps?.dataSource?.length || 0})`
					}
					createButtonProps={{ size: "small" }}
					headerButtons={
						<>
							<ExportButton onClick={triggerExport} loading={exportLoading} />
							<CreateButton />
						</>
					}
				>
					<Table {...tableProps} scroll={{ x: true }} rowKey="id">
						<Table.Column
							dataIndex={"photo"}
							align="center"
							title={translate("common.photo")}
							width={150}
							render={(value) => {
								return value ? (
									<ImageField
										value={API_URL + value[0].url}
										alt={value[0]?.name}
										title={value[0]?.name}
										width={48}
										preview={{ mask: <></> }}
									/>
								) : (
									<span>---</span>
								);
							}}
						/>
						<Table.Column
							// width={breakpoint.xs && 200}
							dataIndex="username"
							title={translate("users.fields.username")}
							defaultSortOrder={getDefaultSortOrder("lastName", sorter)}
							sorter={{ multiple: 2 }}
							showSorterTooltip={false}
						/>
						<Table.Column
							dataIndex="email"
							title={translate("users.fields.email")}
						/>
						<Table.Column
							dataIndex="contact"
							title={translate("users.fields.contact")}
						/>
						<Table.Column
							render={(value, record: any) => {
								return (
									<Form.Item style={{ margin: 0 }}>{record.type}</Form.Item>
								);
							}}
							title={translate("users.fields.type")}
							defaultSortOrder={getDefaultSortOrder("lastName", sorter)}
							sorter={{ multiple: 2 }}
							showSorterTooltip={false}
						/>
						<Table.Column
							key="[province][id]"
							dataIndex={["province", "title"]}
							title={translate("provinces.province")}
						/>
						{userInfo &&
							(JSON.parse(userInfo).type ===
								translate("pages.login.fields.admin") ||
								JSON.parse(userInfo).type ===
									translate("pages.login.fields.user")) && (
								<>
									{/* <Table.Column<IUser>
										title={translate("common.sendEmail")}
										render={(_, record) => (
											<Space>
												<Icons.MailOutlined />
											</Space>
										)}
									/> */}
									<Table.Column<IUser>
										title={translate("table.actions")}
										dataIndex="actions"
										fixed={"right"}
										render={(_, record) => (
											<Space>
												<EditButton
													size="small"
													hideText
													recordItemId={record.id}
												/>
												{record.type !==
													translate("pages.login.fields.admin") && (
													<DeleteButton
														size="small"
														hideText
														recordItemId={record.id}
													/>
												)}
											</Space>
										)}
									/>
								</>
							)}
					</Table>
				</List>
			</Col>
		</Row>
	);
};

const Filter: React.FC<{ formProps: any }> = ({ formProps }) => {
	const translate = useTranslate();
	const { userInfo } = useContext(LoginContext);
	const { selectProps: ProvinceSelectProps } = useSelect<IProvince>({
		resource: "provinces",
	});

	return (
		<Form layout="vertical" {...formProps}>
			<Form.Item label={translate("users.fields.username")} name="username">
				<Input
					placeholder={translate("users.fields.username")}
					prefix={<Icons.SearchOutlined />}
				/>
			</Form.Item>

			<Form.Item label={translate("users.fields.email")} name="email">
				<Input
					placeholder={translate("users.fields.email")}
					prefix={<Icons.SearchOutlined />}
				/>
			</Form.Item>

			<Form.Item label={translate("users.fields.contact")} name="contact">
				<Input
					placeholder={translate("users.fields.contact")}
					prefix={<Icons.SearchOutlined />}
				/>
			</Form.Item>

			{userInfo &&
				JSON.parse(userInfo).type === translate("pages.login.fields.admin") && (
					<>
						<Form.Item label={translate("provinces.province")} name="province">
							<Select
								{...ProvinceSelectProps}
								allowClear
								placeholder={translate("provinces.province")}
							/>
						</Form.Item>

						<Form.Item label={translate("users.fields.type")} name="type">
							<Select
								placeholder={translate("pages.login.fields.type")}
								options={[
									{
										value: translate("common.all"),
										label: translate("common.all"),
									},
									{
										value: translate("pages.login.fields.admin"),
										label: translate("pages.login.fields.admin"),
									},
									{
										value: translate("pages.login.fields.federationAdmin"),
										label: translate("pages.login.fields.federationAdmin"),
									},
									{
										value: translate("pages.login.fields.provinceAdmin"),
										label: translate("pages.login.fields.provinceAdmin"),
									},
									{
										value: translate("pages.login.fields.sportMajorAdmin"),
										label: translate("pages.login.fields.sportMajorAdmin"),
									},
									{
										value: translate("pages.login.fields.user"),
										label: translate("pages.login.fields.user"),
									},
								]}
							/>
						</Form.Item>

						<Form.Item label={translate("common.blocked")} name="blocked">
							<Switch />
						</Form.Item>
					</>
				)}

			<Form.Item>
				<Button htmlType="submit" type="primary">
					{translate("common.filter")}
				</Button>
			</Form.Item>
		</Form>
	);
};

import React from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
} from "@pankod/refine-core";
import {
	Edit,
	ErrorComponent,
	Form,
	Input,
	Select,
	useForm,
	useSelect,
} from "@pankod/refine-antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { Icons } from "@pankod/refine-antd";

import { ISportMajor, ISportStyle } from "interfaces";
import { IsSuperAdmin } from "helpers/utilities";

export const SportStyleEdit: React.FC<IResourceComponentsProps> = () => {
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();

	const { formProps, saveButtonProps, queryResult, formLoading } =
		useForm<ISportStyle>({
			metaData: { populate: ["sport_major"] },
		});

	const { selectProps } = useSelect<ISportMajor>({
		resource: "sport-majors",
		defaultValue: queryResult?.data?.data?.sport_major?.id,
	});

	if (IsSuperAdmin() === false) {
		return <ErrorComponent />;
	}

	return (
		<Edit
			isLoading={formLoading}
			saveButtonProps={saveButtonProps}
			goBack={
				currentLocale === "en" ? (
					<Icons.ArrowLeftOutlined />
				) : (
					<Icons.ArrowRightOutlined />
				)
			}
		>
			<Form
				{...formProps}
				layout="vertical"
				// eslint-disable-next-line
				onFinish={(values: any) => {
					return formProps.onFinish?.(mediaUploadMapper(values));
				}}
			>
				<Form.Item
					label={translate("sport-styles.sport-style")}
					wrapperCol={{ span: 14 }}
					name="title"
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					wrapperCol={{ span: 14 }}
					label={translate("sport-majors.sport-major")}
					name={["sport_major", "id"]}
					rules={[
						{
							required: true,
							message: translate(
								"pages.login.errors.fieldRequired",
								"fieldRequired"
							),
						},
					]}
				>
					<Select {...selectProps} />
				</Form.Item>
			</Form>
		</Edit>
	);
};

import { useContext } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	CrudFilters,
	HttpError,
	useNavigation,
	useApiUrl,
} from "@pankod/refine-core";

import {
	List,
	Table,
	useTable,
	getDefaultSortOrder,
	Space,
	ShowButton,
	Badge,
	Icons,
	ErrorComponent,
} from "@pankod/refine-antd";

import { INotification, INotificationFilterVariables } from "interfaces";
import { LoginContext } from "contexts/authContext";
import { axiosInstance } from "authProvider";
import { useParams } from "@pankod/refine-react-router-v6";

export const NotificationSingleList: React.FC<
	IResourceComponentsProps
> = () => {
	const translate = useTranslate();
	const { push } = useNavigation();
	const { userInfo } = useContext(LoginContext);
	const API_URL = useApiUrl();
	const { id: urlReqId } = useParams();

	const { tableProps, sorter } = useTable<
		INotification,
		HttpError,
		INotificationFilterVariables
	>({
		initialSorter: [
			{
				field: "id",
				order: "desc",
			},
		],
		metaData: {
			populate: ["request", "users", "user_id"],
		},
		initialFilter: [
			{
				field: "request.id",
				operator: "eq",
				value: urlReqId,
			},
		],
	});

	if (userInfo) {
		const { type, requests } = JSON.parse(userInfo);
		if (type === translate("pages.login.fields.user")) {
			const myReq = requests.find((e: any) => e.id === Number(urlReqId));
			if (!myReq) {
				return <ErrorComponent />;
			}
		}
	}

	return (
		<List title={translate("common.workflow")}>
			<Table
				{...tableProps}
				rowKey="id"
				pagination={{
					...tableProps.pagination,
				}}
			>
				<Table.Column<{ request: any; isRead: string }>
					dataIndex="isRead"
					key="isRead"
					width={10}
					render={(_, record) =>
						_.toString() === "false" && <Badge dot offset={[10, 0]}></Badge>
					}
				/>
				<Table.Column<{ request: any; accepted: boolean }>
					dataIndex="accepted"
					key="accepted"
					width={10}
					render={(_, record) =>
						_ === true ? (
							<Icons.CheckOutlined />
						) : _ === false ? (
							<Icons.ExclamationOutlined />
						) : (
							<></>
						)
					}
				/>
				<Table.Column
					dataIndex="createdAt"
					key="createdAt"
					width={200}
					title={translate("requests.fields.createdAt")}
					render={(value) => {
						return (
							new Date(value).toLocaleTimeString("fa-IR") +
							"  -  " +
							new Date(value).toLocaleDateString("fa-IR")
						);
					}}
				/>
				<Table.Column
					key="[user_id][username]"
					dataIndex={["user_id", "username"]}
					title={translate("users.user")}
					width={200}
				/>
				<Table.Column
					dataIndex="message"
					key="message"
					title={translate("common.title")}
					defaultSortOrder={getDefaultSortOrder("message", sorter)}
					width={400}
				/>
				<Table.Column
					dataIndex="comment"
					key="comment"
					title={translate("reqNotifs.comments")}
					width={600}
				/>
			</Table>
		</List>
	);
};

import React, { useContext, useState, useEffect } from "react";
import {
	IResourceComponentsProps,
	useTranslate,
	useGetLocale,
	useNavigation,
	useMany,
	HttpError,
	useCreate,
	useGetIdentity,
	useCreateMany,
} from "@pankod/refine-core";
import dayjs from "dayjs";

import {
	Create,
	Form,
	Input,
	Select,
	useForm,
	useSelect,
	Icons,
	Switch,
	Divider,
	Upload,
	ErrorComponent,
	Edit,
	Tag,
	message,
	Checkbox,
	Spin,
} from "@pankod/refine-antd";

import {
	useStrapiUpload,
	getValueProps,
	mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import { IRequest } from "interfaces";

import { TOKEN_KEY, API_URL } from "../../constants";
import { IsSuperAdmin } from "helpers/utilities";
import { LoginContext } from "../../contexts/authContext";
import { useParams } from "@pankod/refine-react-router-v6";
import DatePicker, {
	DayValue,
} from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import moment from "jalali-moment";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { axiosInstance } from "authProvider";

export const RequestEdit: React.FC<IResourceComponentsProps> = () => {
	const [frmLoading, setFrmLoading] = useState<boolean>(false);
	const [draft, setDraft] = useState(false);
	const { id } = useParams();
	const { userInfo } = useContext(LoginContext);
	const translate = useTranslate();
	const locale = useGetLocale();
	const currentLocale = locale();
	const { formProps, saveButtonProps, queryResult, formLoading, onFinish } =
		useForm<IRequest>({
			redirect: false,
			metaData: {
				populate: [
					"sport_major",
					"sport_style",
					"province",
					"photo",
					"files",
					"paymentReceipt",
				],
			},
		});
	const { TextArea } = Input;
	const { selectProps } = useSelect({
		resource: "sport-majors",
	});

	const [dateOfBirth, setDateOfBirth] = React.useState<any>(null);

	useEffect(() => {
		if (queryResult?.data?.data?.DOB) {
			setDateOfBirth({
				year: Number(
					moment(queryResult?.data?.data.DOB, "YYYY/MM/DD")
						.locale("fa")
						.format("YYYY/MM/DD")
						.split("/")[0]
				),
				month: Number(
					moment(queryResult?.data?.data.DOB, "YYYY/MM/DD")
						.locale("fa")
						.format("YYYY/MM/DD")
						.split("/")[1]
				),
				day: Number(
					moment(queryResult?.data?.data.DOB, "YYYY/MM/DD")
						.locale("fa")
						.format("YYYY/MM/DD")
						.split("/")[2]
				),
			});
		}
	}, [queryResult?.data?.data?.DOB]);

	const { selectProps: sportStylesSelectProps } = useSelect({
		resource: "sport-styles",
	});
	const { selectProps: provincesSelectProps } = useSelect({
		resource: "provinces",
	});
	const { goBack } = useNavigation();
	const { mutate } = useCreateMany();
	const { data: user } = useGetIdentity();

	const { ...uploadProps } = useStrapiUpload;

	if (IsSuperAdmin() === false) {
		if (userInfo) {
			const { type, requests } = JSON.parse(userInfo);
			if (type === translate("pages.login.fields.user")) {
				const myReq = requests.find((e: any) => e.id === Number(id));
				if (!myReq) {
					return <ErrorComponent />;
				}
			} else if (type !== translate("pages.login.fields.user")) {
				return <ErrorComponent />;
			}
		}
	}

	const onChangeDraft = (e: CheckboxChangeEvent) => {
		if (e.target.checked) {
			setDraft(true);
		} else {
			setDraft(false);
		}
	};

	const handleSubmit = async (values: any) => {
		try {
			setFrmLoading(true);
			values = {
				...values,
				DOB: moment.from(
					dateOfBirth.year + "/" + dateOfBirth.month + "/" + dateOfBirth.day,
					"fa",
					"YYYY/MM/DD"
				),
				isDraft: draft,
			};

			const data: any = await onFinish(mediaUploadMapper(values));
			const reqId = data?.data?.data?.id;

			let done: boolean = false;
			if (!draft) {
				const { data: sportMajors } = await axiosInstance.get(
					`${API_URL}/api/users?fields[0]=id&filters[$or][0][type][$eq]=${translate(
						"pages.login.fields.sportMajorAdmin"
					)}`
				);

				const { data: federationAdmin } = await axiosInstance.get(
					`${API_URL}/api/users?fields[0]=id&filters[$and][0][type][$eq]=${translate(
						"pages.login.fields.federationAdmin"
					)}`
				);

				const { data: provinceAdmin } = await axiosInstance.get(
					`${API_URL}/api/users?fields[0]=id&filters[$or][0][type][$eq]=${translate(
						"pages.login.fields.provinceAdmin"
					)}&filters[$and][1][province][id][$eq]=${values?.province}`
				);

				mutate(
					{
						resource: "notifications",
						values: [
							{
								message: translate("reqNotifs.new"),
								request: Number(reqId),
								users: federationAdmin,
								user_id: user?.id,
							},
							{
								message: translate("reqNotifs.new"),
								request: Number(reqId),
								users: provinceAdmin,
								user_id: user?.id,
							},
							{
								message: translate("reqNotifs.new"),
								request: Number(reqId),
								users: sportMajors,
								user_id: user?.id,
							},
						],
					},
					{
						onSuccess: (data, variables, context) => {
							redir();
						},
					}
				);
			} else {
				redir();
			}
		} catch (error) {
			setFrmLoading(true);
			console.log(error);
		}
	};

	function redir() {
		if (userInfo) {
			const { type } = JSON.parse(userInfo);
			if (type === translate("pages.login.fields.user")) {
				window.location.replace("/myrequests");
			} else {
				window.location.replace("/requests");
			}
		}
	}

	return (
		<Edit
			isLoading={formLoading}
			title={translate("common.edit")}
			saveButtonProps={saveButtonProps}
			breadcrumb={false}
			footerButtons={({ defaultButtons }) => (
				<>
					<Checkbox onChange={onChangeDraft}>
						{translate("common.saveasdraft")}
					</Checkbox>
					{/* <Button type="primary" onClick={handleSaveDraft}>
						{translate("common.save")}
					</Button> */}
					{defaultButtons}
				</>
			)}
			// goBack={
			// 	currentLocale === "en" ? (
			// 		<Icons.ArrowLeftOutlined />
			// 	) : (
			// 		<Icons.ArrowRightOutlined />
			// 	)
			// }
		>
			<Spin spinning={frmLoading} delay={500}>
				<Form
					{...formProps}
					layout="inline"
					onFinish={(values) => {
						if (
							formProps?.initialValues?.provincialAdminApproval === true &&
							formProps?.initialValues?.federationAdminApproval === true &&
							formProps?.initialValues?.sportmajorAdminApproval === true
						) {
							return message.error(translate("common.reqFinalizedError"));
						} else {
							// goBack();
							// return (
							// 	formProps.onFinish &&
							// 	formProps.onFinish(mediaUploadMapper(values))
							// );
							handleSubmit(values);
						}
					}}
				>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.name")}
						name="name"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.lastName")}
						name="lastName"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.fatherName")}
						name="fatherName"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.DOB")}
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						{/* <Input value={ddata?.data?.data?.DOB} /> */}
						{/* <DatePicker onChange={setDay}
 value={day} shouldHighlightWeekends locale="fa" /> */}
						{/* <DatePicker defaultValue={dayjs("1401-01-01", "YYYY-MM-DD")} /> */}
						<DatePicker
							value={dateOfBirth}
							onChange={setDateOfBirth}
							shouldHighlightWeekends
							locale="fa"
						/>
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.placeOfBirth")}
						name="placeOfBirth"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.job")}
						name="job"
					>
						<Input />
					</Form.Item>
					<Form.Item
						label={translate("requests.fields.education")}
						name="education"
					>
						<Select
							placeholder={translate("requests.fields.education")}
							options={[
								{
									value: translate("requests.fields.uneducated"),
									label: translate("requests.fields.uneducated"),
								},
								{
									value: translate("requests.fields.elementarySchool"),
									label: translate("requests.fields.elementarySchool"),
								},
								{
									value: translate("requests.fields.highSchool"),
									label: translate("requests.fields.highSchool"),
								},
								{
									value: translate("requests.fields.associateDegree"),
									label: translate("requests.fields.associateDegree"),
								},
								{
									value: translate("requests.fields.bachelorDegree"),
									label: translate("requests.fields.bachelorDegree"),
								},
								{
									value: translate("requests.fields.masterDegree"),
									label: translate("requests.fields.masterDegree"),
								},
								{
									value: translate("requests.fields.PHD"),
									label: translate("requests.fields.PHD"),
								},
								{
									value: translate("requests.fields.Other"),
									label: translate("requests.fields.Other"),
								},
							]}
						/>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.studyMajor")}
						name="studyMajor"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.gender")}
						name="gender"
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select
							placeholder={translate("requests.fields.gender")}
							options={[
								{
									value: translate("common.male"),
									label: translate("common.male"),
								},
								{
									value: translate("common.female"),
									label: translate("common.female"),
								},
							]}
						/>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.contactOne")}
						name="contactOne"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.contactTwo")}
						name="contactTwo"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.identificationCard")}
						name="identificationCard"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.identificationID")}
						name="identificationID"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.tazkiraID")}
						name="tazkiraID"
					>
						<Input />
					</Form.Item>

					<Divider />

					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.CoachingCertificate")}
						name="hasCoachingCertificate"
					>
						{queryResult?.data?.data?.hasCoachingCertificate === true ? (
							<Switch defaultChecked />
						) : (
							<Switch />
						)}
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.technicalLevel")}
						name="technicalLevel"
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("requests.fields.ArbitrationCertificate")}
						name="hasArbitrationCertificate"
					>
						{queryResult?.data?.data?.hasArbitrationCertificate === true ? (
							<Switch defaultChecked />
						) : (
							<Switch />
						)}
					</Form.Item>

					<Divider />

					<Form.Item
						style={{ marginBottom: 10, width: 200 }}
						label={translate("sport-majors.sport-major")}
						name={["sport_major", "id"]}
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select {...selectProps} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: 200 }}
						label={translate("sport-styles.sport-style")}
						name={["sport_style", "id"]}
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select {...sportStylesSelectProps} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: 200 }}
						label={translate("provinces.province")}
						name={["province", "id"]}
						rules={[
							{
								required: true,
								message: translate(
									"pages.login.errors.fieldRequired",
									"fieldRequired"
								),
							},
						]}
					>
						<Select {...provincesSelectProps} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.currentClubMembershipLabel")}
						name="currentClubMembership"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.nationalTeamMembershipLabel")}
						name="nationalTeamMembership"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.formerMembershipsLabel")}
						name="formerMemberships"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.currentAddress")}
						name="currentAddress"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.mainAddress")}
						name="mainAddress"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Form.Item
						style={{ marginBottom: 10, width: "100%" }}
						label={translate("requests.fields.honors")}
						name="honors"
					>
						<TextArea rows={2} />
					</Form.Item>

					<Divider />

					<p>{translate("requests.titles.mandatoryFilesLable")}</p>
					<Divider />

					<Form.Item label={translate("common.photo")}>
						<Form.Item
							name="photo"
							valuePropName="fileList"
							getValueProps={(data) => getValueProps(data, API_URL)}
							noStyle
						>
							<Upload.Dragger
								beforeUpload={(file: any, FileList: any) => {
									if (Number(file.size / 1024 / 1024) > 1) {
										message.error(translate("common.fileSizeMsg"));
										return Upload.LIST_IGNORE;
									} else {
										return true;
									}
								}}
								name="files"
								action={`${API_URL}/api/upload`}
								headers={{
									Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
								}}
								listType="picture"
								multiple={false}
								{...uploadProps}
								accept=".png,.jpeg"
							>
								<p className="ant-upload-text">
									{translate("common.photoMsg")}
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Form.Item>

					<Form.Item label={translate("common.files")}>
						<Form.Item
							name="files"
							valuePropName="fileList"
							getValueProps={(data) => getValueProps(data, API_URL)}
							noStyle
						>
							<Upload.Dragger
								beforeUpload={(file: any, FileList: any) => {
									if (Number(file.size / 1024 / 1024) > 1) {
										message.error(translate("common.fileSizeMsg"));
										return Upload.LIST_IGNORE;
									} else {
										return true;
									}
								}}
								name="files"
								action={`${API_URL}/api/upload`}
								headers={{
									Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
								}}
								listType="picture"
								multiple
								{...uploadProps}
							>
								<p className="ant-upload-text">
									{translate("common.filesMsg")}
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Form.Item>

					<Divider />
					<Form.Item
						style={{ marginBottom: 10 }}
						label={translate("common.paymentReceiptNo")}
						name="paymentReceiptNo"
					>
						<Input />
					</Form.Item>

					<Form.Item label={translate("common.paymentReceipt")}>
						<Form.Item
							name="paymentReceipt"
							valuePropName="fileList"
							getValueProps={(data) => getValueProps(data, API_URL)}
							noStyle
						>
							<Upload.Dragger
								beforeUpload={(file: any, FileList: any) => {
									if (Number(file.size / 1024 / 1024) > 1) {
										message.error(translate("common.fileSizeMsg"));
										return Upload.LIST_IGNORE;
									} else {
										return true;
									}
								}}
								name="files"
								action={`${API_URL}/api/upload`}
								headers={{
									Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
								}}
								listType="picture"
								multiple={false}
								{...uploadProps}
								accept=".png,.jpeg"
							>
								<p className="ant-upload-text">
									{translate("common.photoMsg")}
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Form.Item>
				</Form>
			</Spin>
		</Edit>
	);
};
